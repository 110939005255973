export const en = {
  // ? Meta
  'app.meta.title': 'Personal account | Cyberprotect',

  // ? Unit
  'unit.b': 'B',
  'unit.kb': 'KB',
  'unit.mb': 'MB',
  'unit.gb': 'GB',
  'unit.tb': 'TB',
  'unit.pb': 'PB',
  'unit.eb': 'EB',
  'unit.zb': 'ZB',
  'unit.yb': 'YB',

  // ? Words
  'word.and': 'and',
  'word.no': 'No',
  'word.and-more': 'and more {count}',

  // ? Countries
  'country.russia': 'Russian Federation',
  'country.belarus': 'Republic of Belarus',

  // ? Dictionary
  'dictionary.selected': 'Selected',
  'dictionary.ruble': '0 rubles | {count} ruble | {count} rubles',
  'dictionary.file': '0 files | {count} file | {count} files',
  'dictionary.year': '0 years | {count} year | {count} years',
  'dictionary.day': '0 days | {count} day | {count} days',
  'dictionary.machine': '{count} machines',
  'dictionary.version': '0 versions | {count} version | {count} versions ',
  'dictionary.seconds': '0 seconds | {count} second | {count} seconds',
  'dictionary.in.seconds': '0 seconds | {count} second | {count} seconds',
  'dictionary.computer': '0 computers | {count} computer | {count} computers',
  'dictionary.of-computers':
    '0 computers | {count} computer | {count} computers',
  'dictionary.elements': '0 items | {count} item |  {count} items',
  'dictionary.element-selected':
    '0 items selected | {count} item selected | | {count} items selected',
  'dictionary.selected-elements':
    'Selected 0 items | Selected {count} item | Selected {count} items',
  'dictionary.selected-elements-size':
    'Selected 0 items | Selected {count} item ({size}) | Selected {count} items ({size})',

  // ? Documents
  'document.label.wr-file': 'File',
  'document.label.wr-folder': 'Folder',
  'document.label.wr-qt': 'File "QT"',
  'document.label.wr-webp': 'File "WEBP"',
  'document.label.wr-wm': 'File "WM"',
  'document.label.wr-7z': 'File "7Z"',
  'document.label.wr-ota': 'File "OTA"',
  'document.label.wr-apk': 'File "APK"',
  'document.label.wr-zip': 'File "ZIP"',
  'document.label.wr-rar': 'File "RAR"',
  'document.label.wr-tar': 'File "TAR"',
  'document.label.wr-bz2': 'File "BZ2"',
  'document.label.wr-gzip': 'File "GZ"',
  'document.label.wr-cab': 'File "CAB"',
  'document.label.wr-jar': 'File "JAR"',
  'document.label.wr-csv': 'File "CSV"',
  'document.label.wr-iso': 'File "ISO"',
  'document.label.wr-ttc': 'File "TTC"',
  'document.label.wr-ttf': 'File "TTF"',
  'document.label.wr-otf': 'File "OTF"',
  'document.label.wr-exe': 'File "EXE"',
  'document.label.wr-dll': 'File "DLL"',
  'document.label.wr-dot': 'File "DOT"',
  'document.label.wr-jpg': 'File "JPG"',
  'document.label.wr-gif': 'File "GIF"',
  'document.label.wr-png': 'File "PNG"',
  'document.label.wr-bmp': 'File "BMP"',
  'document.label.wr-mp3': 'File "MP3"',
  'document.label.wr-x-au': 'File "AU"',
  'document.label.wr-mp4': 'File "MP4"',
  'document.label.wr-mpa': 'File "MPA"',
  'document.label.wr-ac3': 'File "AC3"',
  'document.label.wr-pdf': 'File "PDF"',
  'document.label.wr-odp': 'File "ODP"',
  'document.label.wr-ods': 'File "ODS"',
  'document.label.wr-odt': 'File "ODT"',
  'document.label.wr-rtf': 'File "RTF"',
  'document.label.wr-txt': 'File "TXT"',
  'document.label.wr-svg': 'File "SVG"',
  'document.label.wr-eps': 'File "EPS"',
  'document.label.wr-cdr': 'File "CDR"',
  'document.label.wr-cdt': 'File "CDT"',
  'document.label.wr-wmf': 'File "WMF"',
  'document.label.wr-raw': 'File "RAW"',
  'document.label.wr-mkv': 'File "MKV"',
  'document.label.wr-avi': 'File "AVI"',
  'document.label.wr-wmv': 'File "WMV"',
  'document.label.wr-wmx': 'File "WMX"',
  'document.label.wr-fb2': 'File "FB2"',
  'document.label.wr-epub': 'File "EPUB"',
  'document.label.wr-mobi': 'File "MOBI"',
  'document.label.wr-mpeg': 'File "MPEG"',
  'document.label.wr-potx': 'File "POTX"',
  'document.label.wr-ppsx': 'File "PPSX"',
  'document.label.wr-xodp': 'File "XODP"',
  'document.label.wr-xods': 'File "XODS"',
  'document.label.wr-xodt': 'File "XODT"',
  'document.label.wr-x-wav': 'File "WAV"',
  'document.label.wr-flac': 'File "FLAC"',
  'document.label.wr-jpeg': 'File "JPEG"',
  'document.label.wr-tiff': 'File "TIFF"',
  'document.label.wr-dotx': 'File "DOTX"',
  'document.label.wr-sfnt': 'File "SFNT"',
  'document.label.wr-html': 'File "HTML"',
  'document.label.wr-woff': 'File "WOFF"',
  'document.label.wr-woff2': 'File "WOFF2"',
  'document.label.wr-gtar': 'File "TAR.GZ"',
  'document.label.wr-movie': 'File "MOVIE"',
  'document.label.wr-vcard': 'File "VCARD"',
  'document.label.wr-x-aiff': 'File "AIFF"',
  'document.label.wr-basic': 'File "BASIC"',
  'document.label.wr-sp-midi': 'File "MIDI"',
  'document.label.wr-x-ms-wma': 'File "WMA"',
  'document.label.wr-sony-arw': 'File "ARW"',
  'document.label.wr-fuji-raf': 'File "RAF"',
  'document.label.wr-sony-srf': 'File "SRF"',
  'document.label.wr-sony-sr2': 'File "SR2"',
  'document.label.wr-photoshop': 'File "PSD"',
  'document.label.wr-canon-cr2': 'File "CR2"',
  'document.label.wr-canon-crw': 'File "CRW"',
  'document.label.wr-adobe-dng': 'File "DNG"',
  'document.label.wr-kodak-kdc': 'File "KDC"',
  'document.label.wr-kodak-dcr': 'File "DCR"',
  'document.label.wr-epson-erf': 'File "ERF"',
  'document.label.wr-nikon-nef': 'File "NEF"',
  'document.label.wr-nikon-nrw': 'File "NRW"',
  'document.label.wr-pentax-pef': 'File "PEF"',
  'document.label.wr-minolta-mrw': 'File "MRW"',
  'document.label.wr-olympus-orf': 'File "ORF"',
  'document.label.wr-keynote': 'File "Keynote"',
  'document.label.wr-tiff-fx': 'File "TIFF-FX"',
  'document.label.wr-panasonic-raw': 'File "RAW"',
  'document.label.no-data': 'No data',
  'document.label.txt': 'Text document',
  'document.label.wr-disk-backup': 'Disk',
  'document.label.wr-file-backup': 'File',
  'document.label.wr-email': 'Element Outlook',
  'document.label.wr-mobile-backup': 'Mobile',
  'document.label.wr-full-backup': 'Full PC copy',
  'document.label.wr-xls': 'Microsoft Excel sheet',
  'document.label.wr-doc': 'Document Microsoft Word',
  'document.label.wr-docx': 'Document Microsoft Word',
  'document.label.wr-ppt': 'Microsoft Power Point presentation',

  // ? Fields label
  'field.label.disk': 'Disk',
  'field.label.search': 'Search',
  'field.label.types': 'All types',
  'field.label.name': 'Name',
  'field.label.type': 'Type',
  'field.label.colon.type': 'Type:',
  'field.label.size': 'Size',
  'field.label.colon.size': 'Size:',
  'field.label.list': 'List',
  'field.label.tile': 'Tiles',
  'field.label.created': 'Created',
  'field.label.colon.she-created': 'Created:',
  'field.label.colon.last-created': 'Last created:',
  'field.label.versions': 'Versions',
  'field.label.colon.versions': 'Versions:',
  'field.label.release': 'Release',
  'field.label.modified': 'Modified',
  'field.label.device': 'Device',
  'field.label.colon.release': 'Release:',
  'field.label.total-size': 'Total size',
  'field.label.colon.total-size': 'Total size:',
  'field.label.colon.last-size': 'Size of the last:',
  'field.label.large-tile': 'Large tiles',
  'field.label.latest-version': 'Latest version',

  // ? Actions (button)
  'button.action.close': 'Close',
  'button.action.delete': 'Delete',
  'button.action.cancel': 'Cancel',
  'button.action.browse': 'Browse',
  'button.action.download': 'Download',
  'button.action.download.all': 'Download all',
  'button.action.download.vcard': 'Download vCard',
  'button.action.unselect': 'Unselect',
  'button.action.delete.archive': 'Delete archive',
  'button.action.only-gallery': 'Gallery only',
  'button.action.only-contacts': 'Contacts only',
  'button.action.load-more': 'Load more',
  'button.action.delete.backup': 'Delete backup',
  'button.action.delete.all-backup': 'Delete the entire backup',
  'button.action.download.backup': 'Download the backup',
  'button.action.download.backup-version': 'Download version',
  'button.action.download.all-archive': 'Download full archive',

  // ? Dropdown
  'dropdown.item-name.all-backup': 'All the backups',
  'dropdown.item-name.contacts': 'Contacts',
  'dropdown.item-name.gallery': 'Gallery',

  // ? Table
  'component.table.label.no-data': 'No data',
  'component.table.content.no-data': 'No data to display',
  'component.table.content.empty-folder': 'This folder is empty.',
  'component.table.content.search-two-symbols':
    'Enter at least 2 characters to search.',
  'component.table.content.empty-search': 'The search was inconclusive.',
  'component.table.content.empty-backups': 'Right now you have no backups.',
  'component.table.content.empty-archives': 'Right now you have no archives.',
  'component.table.content.loading-archives':
    'The list of archives is loading...',
  'component.table.content.loading-backups': 'The backup list is loading....',

  // ? Feedback
  'feedback.title.registered': 'Confirm your sign-up',
  'feedback.title.reset-password':
    'The link to reset your password is no longer valid.',
  'feedback.title.not-activated': 'Sign-up is not complete',
  'feedback.title.message-sent': 'Message sent',
  'feedback.title.account-already-registered.link-no-valid':
    'Link is no longer valid.',
  'feedback.text.account-already-registered.try-to-login':
    'Log in to your account or confirm your registration.',
  'feedback.text.registered':
    "We've mailed you a link to {email}. Follow the link to complete your sign-up and start using your personal account.",
  'feedback.text.not-activated':
    'When you started signing up, we mailed you a link to {email}. Follow the link to complete your sign-up and start using your personal account.',
  'feedback.text.sent-message':
    "We've mailed you a link to {email}. Follow the link to set a new password.",
  'feedback.text.no-message-came':
    "If you haven't received the message, check your Spam folder, request resending the message or contact our support team at",
  'feedback.text.resend-messages':
    'Message sent. It can be sent again after {time}',
  'feedback.button.resent-message': 'Send message again',
  'feedback.link.to-forgot': 'Request link again',
  'feedback.link.account-already-registered': 'Sign in',

  // ? Notify
  'notify.backup.reject.invalid-version-id':
    'Invalid backup version id «{version}»',
  'notify.archive.reject.invalid-version-id':
    'Invalid archive version identifier «{version}»',
  'notify.backup.reject.archive-not-found': 'Archive «{name}» not found',
  'notify.backup.reject.backup-not-found': 'Backup «{name}» not found',
  'notify.backup.reject.file-not-found': 'File «{name}» not found',
  'notify.archive.reject.version-not-found':
    'Archive version «{version}» was not found',
  'notify.backup.reject.version-not-found':
    'Backup version «{version}» was not found',
  'notify.download.success': 'Download completed',
  'notify.download-backup-file.success':
    'Download of file «{name}» is complete',
  'notify.download-backup-folder.success':
    'Download of the «{name}» folder is complete',
  'notify.delete-profile.pending': 'Deleting profile',
  'notify.delete-profile.resolve': 'Profile deleted',
  'notify.delete-profile.reject': 'Access denied',
  'notify.change-password.pending': 'Changing your password',
  'notify.change-password.resolve': 'The account password has been changed.',
  'notify.change-password.go-to-cyber-backup':
    'Open Cyber Backup Personal and re-log in.',
  'notify.edit-profile.pending': 'Saving user information',
  'notify.edit-profile.resolve': 'User information has been successfully saved',
  'notify.products.button.auto-renewal-enabled': 'Auto renewal enabled',
  'notify.products.button.auto-renewal-disabled': 'Auto renewal disabled',
  'notify.profile.subscribe-newsletter-enabled':
    'You have successfully subscribed to the newsletter',
  'notify.profile.subscribe-newsletter-disabled':
    'You have successfully unsubscribed',
  'notify.delete-backup.backup-in-use':
    'Backup is in use. Please try again later. | Backup {names} is in use. Please try again later. | Backups {names} are in use. Please try again later.',

  // ? Modals
  // Delete profile
  'modal.delete-profile.header.title': 'Delete profile?',
  'modal.delete-profile.content.description':
    'This action is irreversible. Enter your password to confirm.',
  'modal.delete-profile.action.cancel': 'Cancel',
  'modal.delete-profile.action.delete': 'Delete',

  // License key
  'modal.license-key.title.adding-license-key': 'Adding a license key',
  'modal.license-key.title.adding-license-file': 'Adding a license file',
  'modal.license-key.sub-title.added-license-key': 'Added license key',
  'modal.license-key.sub-title.added-license-file': 'Added license file',
  'modal.license-key.button.submit.done': 'Done',
  'modal.license-key.button.submit.try-again': 'Try again',
  'modal.license-key.status.checking': 'Checking',
  'modal.license-key.status.checked': 'Checked',
  'modal.license-key.status.checking-key': 'Checking a key',
  'modal.license-key.status.wrong-file': 'Wrong file',
  'modal.license-key.description.not-available':
    'You are unable to activate this key, please contact support.',

  // Plan expansion
  'modal.plan-expansion.title': 'Expanding license',
  'modal.plan-expansion.current-plan': 'Current license',
  'modal.plan-expansion.new-plan': 'New license',
  'modal.plan-expansion.word.year': 'year',
  'modal.plan-expansion.word.disabled': 'disabled',
  'modal.plan-expansion.word.current': 'current',
  'modal.plan-expansion.word.upper-current': 'Current',
  'modal.plan-expansion.sub-title.pc': 'Computers',
  'modal.plan-expansion.info.pc': 'Specify the number of computers',
  'modal.plan-expansion.info.maximum-pc': 'Maximum number reached',
  'modal.plan-expansion.info.maximum-cloud': 'Maximum capacity reached',
  'modal.plan-expansion.sub-title.cloud': 'Cloud storage',
  'modal.plan-expansion.info.cloud': 'Specify the capacity of the cloud',
  'modal.plan-expansion.description.cloud':
    'Include a cloud storage to store your backups.',
  'modal.plan-expansion.info.duration':
    'Thus, your new subscription will last for',
  'modal.plan-expansion.info.subscription-creation':
    'The current subscription will be terminated and a new one created.',
  'modal.plan-expansion.info.unused-period':
    'for unused days will be added to the new subscription as additional days.',
  'modal.plan-expansion.submit.cancel': 'Cancel',
  'modal.plan-expansion.submit.buy': 'Buy',

  // Delete backup
  'modal.delete-backup.title': 'Delete the backup?',
  'modal.delete-all-backup.title': 'Delete the entire backup?',
  'modal.delete-backup.description':
    'The «{name}» backup will be irretrievably deleted, including all versions of it',
  'modal.delete-backup.deleted': 'Backup «{name}» deleted',

  // Delete archive
  'modal.delete-archive.title': 'Delete the archive?',
  'modal.delete-archive.description':
    'Archive «{name}» will be irretrievably deleted',
  'modal.delete-archive.deleted': 'Archive «{name}» has been deleted',

  // Common error
  'modal.common-error.title': 'An error occurred',
  'modal.common-error.text.work-with-problem':
    'We are already working to fix the issue.',
  'modal.common-error.text.try-after': 'Please try again after a while.',

  // Backup versions
  'modal.backup-versions.title': 'Version selection',

  // Phone mobile backup category
  'modal.phone.backup-category.title': 'Part of the mobile backup',

  // Phone mobile backup menu
  'modal.phone.backup-menu.title': 'Mobile backup',

  // Phone mobile backup contact
  'modal.phone.backup-contact.title':
    'To copy, tap a phone number, email, or address',

  // ? Forms
  'form.button.submit.next': 'Next',
  'form.button.submit.cancel': 'Cancel',
  'form.button.submit.send': 'Send',
  'form.button.submit.send-request': 'Send request',

  // FORM: Login
  'form.login.title': 'Sign in',
  'form.login.question.has-account': 'No account?',
  'form.login.link.register': 'Sign up',
  'form.login.link.forgot-password': 'Forgot your password?',
  'form.login.button.submit': 'Sign in',

  // FORM: Register
  'form.register.title': 'Sign up',
  'form.register.tab.personal-account': 'Personal account',
  'form.register.tab.business-account': 'Business account',
  'form.register.question.has-account': 'Already have an account?',
  'form.register.link.login': 'Sign in',
  'form.register.link.agree': 'I consent to',
  'form.register.link.data-handling': 'the processing of my personal data',
  'form.register.link.agreement':
    'I agree to receive news and special offers by Cyberprotect',
  'form.register.button.submit': 'Sign up',

  // FORM: Activate
  'form.activate.title': 'Sign up via link',
  'form.activate.button.submit': 'Set password',

  // FORM: Deleted
  'form.deleted.title': 'You deleted your account',
  'form.deleted.link.sign-in': 'sign in',
  'form.deleted.link.create': 'create',
  'form.deleted.word.new': 'new',
  'form.deleted.phrase.you-can': 'You can',
  'form.deleted.phrase.another-account': 'with another account or',

  // FORM: Forgot
  'form.forgot.title': 'Restore password',
  'form.forgot.link.root': 'Sign in',
  'form.forgot.button.submit': 'Submit',
  'form.forgot.text.forgot-info-message':
    'Enter the email address you used to register your personal account, and we will send you a link to reset your password.',

  // FORM: Reset
  'form.reset.title': 'Restore password',
  'form.reset.text.reset-info-message': 'Create a new password',
  'form.reset.button.submit': 'Reset password',

  // FORM: Change password
  'form.change-password.title': 'Changing your password',
  'form.change-password.link.sign-in': 'Sign in',
  'form.change-password.link.forgot-password': 'Forgot your password?',
  'form.change-password.button.change': 'Change',

  // FORM: Delete profile
  'form.delete-profile.button.cancel': 'Cancel',
  'form.delete-profile.button.delete': 'Delete',

  // FORM: Edit profile
  'form.edit-profile.title': 'Changing user information',
  'form.edit-profile.button.cancel': 'Cancel',
  'form.edit-profile.button.submit': 'Save',

  // FORM: Add license
  'form.add-license.button.submit': 'Add',
  'form.add-license.button.close': 'Close',

  // FORM: Problem report
  'form.problem-report.info.screenshot':
    'Add screenshots and a product system report if necessary. To learn how to create a system report, see the corresponding knowledge base article.',
  'form.problem-report.button.system-report': 'How to create a system report',

  // FORM: Request
  'form.request.info.additional-files':
    'If necessary, attach any additional files that may be helpful in processing your request.',

  // FIELD: Label
  'form.field.label.dates': 'Dates',
  'form.field.label.phone': 'Phone',
  'form.field.label.phones': 'Phones',
  'form.field.label.product': 'Product',
  'form.field.label.product-version': 'Product version',
  'form.field.label.os': 'Operating system',
  'form.field.label.priority': 'Priority',
  'form.field.label.priority.urgent': 'Urgent',
  'form.field.label.priority.high': 'High',
  'form.field.label.priority.normal': 'Normal',
  'form.field.label.priority.low': 'Low',
  'form.field.label.subject': 'Request subject',
  'form.field.label.type': 'Type of request',
  'form.field.label.description': 'Additional information',
  'form.field.label.address': 'Address',
  'form.field.label.country': 'Country',
  'form.field.label.company': 'Company',
  'form.field.label.email': 'Email',
  'form.field.label.emails': 'Emails',
  'form.field.label.password': 'Password',
  'form.field.label.old-password': 'Old password',
  'form.field.label.new-password': 'New password',
  'form.field.label.confirm-password': 'Confirm password',
  'form.field.label.first-name': 'First name',
  'form.field.label.last-name': 'Last name',
  'form.field.label.company-name': 'Company name',
  'form.field.label.inn': 'INN',
  'form.field.label.unp': 'UNP',
  'form.field.label.kpp': 'KPP',
  'form.field.label.ogrn': 'OGRN',
  'form.field.label.oked': 'OKED',
  'form.field.label.license-key': 'License key',

  // FIELD: placeholder
  'form.field.placeholder.select': 'Select',
  'form.field.placeholder.search-archive-name': 'Search by archive name',
  'form.field.placeholder.search-backup-name': 'Search by backup name',

  // FIELD: Validate

  'form.field.validate.min-value': 'Minimum number of {length} characters.',
  'form.field.validate.max-value': 'Maximum number of {length} characters.',
  'form.field.validate.wrong-license-key': 'Wrong key',
  'form.field.validate.phone-length': 'Phone must contain {length} characters.',
  'form.field.validate.phone-min-length':
    'Enter at least {length} of characters.',
  'form.field.validate.phone-max-length':
    'Enter no more than {length} characters.',
  'form.field.validate.phone-incorrect': 'Incorrect phone.',
  'form.field.validate.phone-required': 'Enter phone.',
  'form.field.validate.subject-required': 'Enter the subject.',
  'form.field.validate.country-required': 'Choose a country.',
  'form.field.validate.system-required': 'Select the operating system.',
  'form.field.validate.product-required': 'Select a product.',
  'form.field.validate.field-required': 'The field must be filled in.',
  'form.field.validate.email-required': 'Enter your email.',
  'form.field.validate.email-incorrect': 'Incorrect email.',
  'form.field.validate.email-not-activated': 'Email is not activated.',
  'form.field.validate.email-match':
    'There is no user with this email address.',
  'form.field.validate.email-used':
    'A user with this email address is already registered.',
  'form.field.validate.cloud-email-used':
    'This account is registered in Cyber Cloud. Please use a different email.',
  'form.field.validate.password-required': 'Enter password.',
  'form.field.validate.new-password-required': 'Enter a new password.',
  'form.field.validate.password-default': 'Input error.',
  'form.field.validate.password-match': 'Passwords do not match.',
  'form.field.validate.password-used':
    'This password is already in use. Please try another one.',
  'form.field.validate.password-wrong': 'Incorrect password.',
  'form.field.validate.password-incorrect': 'Incorrect password.',
  'form.field.validate.password-invalid':
    'Password can only contain Latin letters, numbers and basic special characters.',
  'form.field.validate.old-password-wrong':
    'The old password was not entered correctly.',
  'form.field.validate.password-min':
    'Minimum password length is {length} characters.',
  'form.field.validate.password-max':
    'Maximum password length is {length} characters.',
  'form.field.validate.email-max':
    'Maximum length of an email is {length} characters.',
  'form.field.validate.confirm-password-required': 'Enter verified password.',

  'form.field.validate.first-name-required': 'Enter first name.',
  'form.field.validate.first-name-max':
    'Maximum length of a {length} character name.',
  'form.field.validate.last-name-required': 'Enter last name.',
  'form.field.validate.last-name-max':
    'Maximum length of the last name is {length} characters.',
  'form.field.validate.company-name-required': 'Enter your company name.',
  'form.field.validate.inn-min': 'Enter at least {length} numbers.',

  // FIELD: Value
  'form.field.value.another-problem': 'Another problem',
  'form.field.value.product-problem': 'Problem with the product',

  /**
   * ? PAGE: Products
   */
  'product.cyber-backup-personal': 'Cyber Backup Personal',
  'product.cyber-backup': 'Cyber Backup',
  'product.cyber-infrastructure': 'Cyber Infrastructure',
  'page.products.progress.license-until': 'Available until {date}',
  'page.products.category.button.download': 'Download',

  'page.products.product.word.or': 'or',
  'page.products.product.word.sub-until': 'until',
  'page.products.product.word.manually': 'manually.',
  'page.products.product.word.trial': 'Trial',
  'page.products.product.word.subscribe': 'Subscription',
  'page.products.product.word.permanent': 'Permanent',
  'page.products.product.word.auto-renewal': 'Auto renewal',
  'page.products.product.word.ended': 'ended',
  'page.products.product.word.not-renewed': 'not renewed',
  'page.products.product.word.update': 'update',
  'page.products.product.phrase.used-pc': 'Computers used',
  'page.products.product.phrase.you-can': 'You can',
  'page.products.product.phrase.auto-renewed':
    'Subscription will be automatically renewed {days}.',
  'page.products.product.phrase.auto-renewed-today':
    'Subscription will be automatically renewed today.',
  'page.products.product.phrase.charge-failed': 'Charge failed.',
  'page.products.product.phrase.subscription-expired':
    'Subscription expired {date}.',
  'page.products.product.phrase.subscription-suspended':
    'Subscription suspended {date}.',
  'page.products.product.phrase.trial-expired': 'Trial expired {date}.',
  'page.products.product.phrase.trial-expires-in': 'Trial expires in {days}.',
  'page.products.product.phrase.subscription-expires-in':
    'Subscription expires in {days}.',
  'page.products.product.phrase.subscription-expires-today':
    'Subscription expires today.',
  'page.products.product.phrase.trial-expires-today': 'Trial expires today.',
  'page.products.product.phrase.left-day': '{days} more.',
  'page.products.product.phrase.subscribe-will-be-updated':
    'Subscription will be updated',
  'page.products.product.button.enable-auto-renewal-subscription':
    'Subscription auto renewal',
  'page.products.product.info.disable-auto-renewal':
    'You have automatic subscription renewal enabled. You can turn it off at any time.',
  'page.products.product.button.done': 'Done',
  'page.products.product.button.close': 'Close',
  'page.products.product.button.renew': 'Renew',
  'page.products.product.button.enable-auto-renewal': 'Enable auto renewal',
  'page.products.product.button.popup-enable-auto-renewal':
    'enable auto renewal',
  'page.products.product.button.increase-quota': 'Increase the quota',
  'page.products.product.button.buy-license': 'Buy license',
  'page.products.product.button.popup-buy-license': 'buy license.',
  'page.products.product.button.popup-buy-new-license': 'buy new license.',
  'page.products.product.button.popup-renew': 'renew subscription',
  'page.products.detail.word.out-of': 'of',
  'page.products.detail.cell-name-license': 'License',
  'page.products.detail.cell-name-dist': 'Distribution kit',
  'page.products.detail.cell-title-maximum-licenses':
    'Maximal license selected',
  'page.products.detail.word.manage': 'Manage',
  'page.products.detail.phrase.manage': 'Manage license',
  'page.products.detail.phrase.computers-used': '{pc} used',
  'page.products.detail.phrase.unlimited-amount': 'Unlimited computers',
  'page.products.detail.phrase.unlimited-amount-short': 'Unlimited',
  'page.products.detail.phrase.cloud-off': 'Cloud is not available',
  'page.products.detail.phrase.cloud-storage': '{cloud} of cloud storage',
  'page.products.detail.button.buy-license': 'Buy another license',
  'page.products.detail.button.increase-quota': 'Increase the quota',
  'page.products.detail.button.expand-license': 'Expand license',
  'page.products.detail.button.download-dist': 'Download',
  'page.products.detail.button.open-docs': 'Read documentation',
  'page.products.detail.modal.title.license-management': 'License management',
  'page.products.detail.modal.empty-pc':
    'This license is not activated on any computer',
  'page.products.detail.modal.sub-title.activated-pc': 'Activated computers',
  'page.products.detail.modal.table-th.name-pc': 'Computer name',
  'page.products.detail.modal.table-th.address': 'The address is',
  'page.products.detail.modal.table-th.activation-date': 'Activation date',
  'page.products.detail.modal.table-td.deactivate': 'Deactivate',

  /**
   * ? PAGE: Profile
   */
  'page.profile.title.user-info': 'User information',
  'page.profile.title.newsletter': 'Newsletter',
  'page.profile.title.password': 'Password',
  'page.profile.text.password-modified': 'Modified {date}',
  'page.profile.text.subscribed': 'You are subscribed to the newsletter',
  'page.profile.text.not-subscribed':
    'You are not subscribed to the newsletter',
  'page.profile.button.remove-profile': 'Delete profile',
  'page.profile.button.edit': 'Edit',
  'page.profile.button.change': 'Change',
  'page.profile.button.subscribe': 'Subscribe',
  'page.profile.button.unsubscribe': 'Unsubscribe',
  'page.profile.cell.first-name': 'First name',
  'page.profile.cell.last-name': 'Last name',
  'page.profile.cell.country': 'Country',
  'page.profile.cell.email': 'Email',
  'page.profile.cell.phone': 'Phone',
  'page.profile.phone.not-specified': `Not specified`,
  'page.profile.first-name.not-specified': `Not specified`,
  'page.profile.last-name.not-specified': `Not specified`,

  /**
   * ? PAGE: Backup
   */
  'page.backup.mobile-name.contacts': 'Contacts',
  'page.backup.mobile-name.messages': 'Messages',
  'page.backup.mobile-name.gallery': 'Gallery',
  'page.backup.mobile-name.calendars': 'Calendars',
  'page.backup.mobile.download-only': 'Download only',
  'page.backup.mobile.view-and-download': 'View and download',
  'page.backup.version.date': 'Version: {date}',
  'page.backup.device.not-recognized': 'Device is not recognized',
  'page.backup.info.files-not-found':
    'Folder «{folder}» is missing from the backup version {version}',
  'page.mobile-backup.info.you-can-do':
    'Contacts and gallery content can be viewed and downloaded, messages and calendars can only be downloaded',

  /**
   * ? PAGE: Backups
   */
  'page.backups.backup.title': 'Backup',
  'page.backups.title': 'Cyber Backup Personal',
  'page.backups.info.no-backups': 'Right now you have no backups',

  /**
   * ? PAGE: Archive
   */
  'page.archive.info.files-not-found':
    'Folder «{folder}» is missing from the archive version {version}',

  /**
   * ? PAGE: Archives
   */
  'page.archives.archive.title': 'Archive',
  'page.archives.title': 'Cyber Backup Personal',
  'page.archives.info.no-archives': 'Right now you have no archives',

  /**
   * ? PAGE: Error
   */
  'page.errors.link.go-home': 'Home',
  'page.errors.title.page-not-found': 'This page was not found',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.undefined': 'Section not found',
  'component.header.title.products': 'Products',
  'component.header.title.profile': 'Profile',
  'component.header.title.backups': 'Backups',
  'component.header.title.archives': 'Archives',
  'component.header.title.support': 'Support',
  'component.header.title.control': 'Control console',
  'component.header.button.title.add-key': 'Add key',
  'component.header.icon.title.order-history': 'Order history',
  'component.header.icon.title.support': 'Support',
  'component.header.icon.title.profile-menu': 'Profile menu',

  /**
   * ? COMPONENT: Footer
   */
  'component.footer.title.cyberprotect': 'Powered by Cyberprotect',

  /**
   * ? COMPONENT: File manager
   */
  'component.file-manager.button.delete': 'Delete file',
  'component.file-manager.submit.attach-file': 'Attach file',
  'component.file-manager.submit.attach-certificate': 'Attach certificate',
  'component.file-manager.submit.license-file': 'Download license file',
  'component.file-manager.phrase.warning-amount': 'You can only download',
  'component.file-manager.phrase.warning-size':
    'The total size of the files cannot be larger than',

  /**
   * ? COMPONENT: Profile menu
   */
  'component.profile.menu.title.empty-products':
    'You have no active products at the moment',
  'component.profile.menu.button.change-password': 'Change password',
  'component.profile.menu.button.language': 'Language',
  'component.profile.menu.button.sign-out': 'Sign out',
  'component.profile.menu.button.buy-product': 'Buy product',
  'component.profile.menu.button.has-key': 'I already have a key',

  /**
   * ? COMPONENT: Support
   */
  'component.support-menu.nav.title.support': 'Technical support',
  'component.support-menu.nav.title.sales-dep': 'Sales department',
  'component.support-menu.nav.title.history': 'History of requests',
  'component.support-menu.header.title.support': 'Contact technical support',
  'component.support-menu.header.title.sales-dep': 'Contact sales department',
  'component.support-menu.header.title.history': 'History of requests',
  'component.support-menu.content.title.contact': 'Contact information',
  'component.support-menu.content.title.product': 'Type of request',
  'component.support-menu.content.title.report': 'Reporting a problem',
  'component.support-menu.content.title.request': 'Request',
  'component.support-menu.content.word.thanks': 'Thank you!',
  'component.support-menu.content.word.appeal': 'Your request',
  'component.support-menu.content.word.registered': 'submitted.',
  'component.support-menu.content.word.attach-certificate':
    'Attach a valid technical support certificate',
  'component.support-menu.content.phrase.contact-you':
    'We will get back to you shortly. You can track the status of your request in the History of requests section.',
  'component.support-menu.content.phrase.contact-you-mobile':
    'We will get back to you shortly. You can track the status of your appeal on his page.',
  'component.support-menu.content.button.support-short-request': 'Tech support',
  'component.support-menu.content.button.support-request': 'Contact support',
  'component.support-menu.content.button.sales-short-request':
    'In sales department',
  'component.support-menu.content.button.sales-request':
    'Contact sales department',
  'component.support-menu.content.button.new-message':
    'Submit one more request',
  'component.support-menu.content.button.go-to-appeals': 'Go to requests',
  'component.support-menu.content.description.contact':
    'Please check if the information filled in is correct. If necessary, you can change your personal data in the Profile section.',
  'component.support-menu.content.description.product':
    'Select the product you are having a problem with. This will help us solve your issue faster.',
  'component.support-menu.content.description.report':
    'Please describe your problem in as many details as possible and attach files that may help us solve your issue.',
  'component.support-menu.content.description.request':
    'Select a product and ask your question.',
  'component.support-menu.footer.button.call-department':
    'Call sales department',
  'component.support-menu.footer.text.phone-department': 'Sales phone number',
  'component.support-menu.footer.button.call-technical':
    'Call technical support',
  'component.support-menu.footer.text.phone-technical': 'Support phone number',
  'component.support-menu.history.table-column-name.contact': 'Request',
  'component.support-menu.history.table-column-name.created': 'Created',
  'component.support-menu.history.table-column-name.status': 'Status',
  'component.support-menu.history.status-name.resolve': 'Decision granted',
  'component.support-menu.history.status-name.in_work': 'In work',
  'component.support-menu.history.status-name.new': 'New',
  'component.support-menu.history.status-name.closed': 'Appeal closed',
  'component.support-menu.history.status-name.hold': 'Deferred',
  'component.support-menu.history.status-name.reject': ' Unresolved, closed',
  'component.support-menu.history.status-name.pending':
    'Waiting for a response from the client',
  'component.support-menu.history.status-name.undefined': 'Not defined',
  'component.support-menu.history.detail.title.overview': 'General information',
  'component.support-menu.history.detail.title.contact': 'Contact information',
  'component.support-menu.history.detail.title.status': 'Current status',
  'component.support-menu.history.detail.label.type': 'Type of request',
  'component.support-menu.history.detail.label.created': 'Created',
  'component.support-menu.history.detail.label.product': 'Product',
  'component.support-menu.history.detail.label.problem': 'Problem description',
  'component.support-menu.history.detail.label.attach': 'Attached files',
  'component.support-menu.history.detail.label.user': 'User',
  'component.support-menu.history.detail.label.phone': 'Phone',
  'component.support-menu.history.detail.label.email': 'Email',
  'component.support-menu.history.detail.label.order-number': 'Order number',
  'component.support-menu.history.empty-content.title':
    'You have no requests at the moment',
  'component.support-menu.history.empty-content.link':
    'Contact technical support',
  'component.support-menu.error.product-not-found': 'Product not found',

  /**
   * ? COMPONENT: Tooltip
   */
  'component.tooltip.cloud.description':
    'On the day that one or more licenses expire, the available cloud storage capacity will decrease.',
  'component.tooltip.plan-expansion.description':
    'The discount is only valid for the first year of purchase.',
  'component.tooltip.support-lock-down.lock-down.not-available':
    "We're sorry, but technical support is temporarily unavailable.",
  'component.tooltip.support-lock-down.you-can': 'You can',
  'component.tooltip.support-lock-down.leave-request': 'leave a request',
  'component.tooltip.support-lock-down.through-website': 'through the website.',

  /**
   * ? COMPONENT: StepBack
   */
  'component.step-back.action-label.go-back': 'Go back',

  /**
   * ? COMPONENT: Notify
   */
  'component.notify.message-error-invalid-account-form-assign-license':
    'A personal account is registered to this email. Use another email.',
  'component.notify.message-assign-license-error':
    'Failed to bind keys to the account. Please contact technical support.',
  'component.notify.message-success.copy-phone': 'The cell phone was copied',
  'component.notify.message-success.copy-email':
    'The mailing address was copied',
  'component.notify.message-success.copy-address': 'The address was copied',
  'component.notify.message-error.copy-failed': 'Failed to copy element',
}
