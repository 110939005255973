import type {
  IWrBackupPayload,
  IAccountIdParams,
  IOperationPayload,
  IOperationResponse,
  IBackupDeletePayload,
  IWrBackupItemPayload,
  TBackupErrorResponse,
  IWrBackupDataResponse,
  IWrArchiveDataResponse,
  TStateOperationResponse,
  IWrBackupVersionsPayload,
} from '@/api/services/webrestore/types'

import store from '@/store'
import axios from '@/api/axios'
import Cookies from '@/helpers/cookies'
import { COOKIE_NAMES } from '@/config/constants'
import { ApiDeleteBackupError } from '@/api/errors'
import { AxiosResponse } from 'axios'

export default {
  /** ==== Operation ==== */

  /**
   * createOperation
   * ? Создать операцию на извлечение содержимого бэкапов из кэша
   *
   * @param {IOperationPayload} data тело запроса резервных копий
   * @returns {Promise<IOperationResponse >} идентификатор операции
   */
  createOperation(data: IOperationPayload): Promise<IOperationResponse> {
    return axios.post('/api/webrestore/v1/operation', data)
  },

  /**
   * getOperation
   * ? Получить состояние текущей операции
   *
   * @param {string } id идентификатор операции
   * @returns {Promise<TStateOperationResponse>} состояние текущей операции
   */
  getOperation(id: string): Promise<TStateOperationResponse> {
    return axios.get(`/api/webrestore/v1/operation/${id}`)
  },

  /** ==== Webrestore ==== */

  /**
   * getArchives
   * ? Получить список архивов
   *
   * @param {IWrBackupPayload} params параметры запроса архивов
   * @returns {Promise<IWrArchiveDataResponse>} список архивов
   */
  getArchives(params?: IWrBackupPayload): Promise<IWrArchiveDataResponse> {
    return axios.get('/api/webrestore/v1/archives', { params })
  },

  /**
   * getBackups
   * ? Получить список резервных копий
   *
   * @param {IWrBackupPayload} params параметры запроса резервных копий
   * @returns {Promise<IWrBackupDataResponse>} список резервных копий
   */
  getBackups(params?: IWrBackupPayload): Promise<IWrBackupDataResponse> {
    return axios.get('/api/webrestore/v1/backups', { params })
  },

  /**
   * getUserBackups
   * ? Получить список резервных копий (для админа)
   *
   * @param {IAccountIdParams} params параметры запроса резервных копий (account_id)
   * @returns {Promise<IWrBackupDataResponse>} список резервных копий
   */
  getUserBackups(params: IAccountIdParams): Promise<IWrBackupDataResponse> {
    const isRoleAdmin = store.getters['account/isRoleAdmin']
    const isRoleDevOps = store.getters['account/isRoleDevOps']
    const config = {
      params,
      withCredentials: isRoleAdmin,
    }

    if (isRoleAdmin || isRoleDevOps) {
      Cookies.set(COOKIE_NAMES.ACCOUNT_ID, params.account_id)
    }

    return axios.get('/api/webrestore/v1/backup-files', config)
  },

  /**
   * getBackupItem
   * ? Получить список (файлов и папок) в конкретной версии конкретного бэкапа
   *
   * @param {IWrBackupItemPayload} params параметры запроса резервных копий
   * @returns {Promise<IWrBackupDataResponse>} список резервных копий
   */
  getBackupItem(params: IWrBackupItemPayload): Promise<IWrBackupDataResponse> {
    return axios.get('/api/webrestore/v1/items', { params })
  },

  /**
   * getBackupVersions
   * ? Получить список версий выбранного бэкапа
   *
   * @param {IWrBackupVersionsPayload} params параметры запроса резервных копий
   * @returns {Promise<IWrBackupDataResponse>} список версий выбранного бэкапа
   */
  getBackupVersions(
    params?: IWrBackupVersionsPayload
  ): Promise<IWrBackupDataResponse> {
    return axios.get('/api/webrestore/v1/versions', { params })
  },

  /**
   * downloadWrBackupFile
   * ? Скачать резервную копию выбранного файл (для ЛК)
   *
   * @param {IWrBackupPayload} params параметры запроса резервных копий
   * @returns {Promise<Blob>} файл
   */
  downloadWrBackupFile(params: IWrBackupPayload): Promise<Blob> {
    return axios.get('/api/webrestore/v1/file', {
      params,
      responseType: 'blob',
    })
  },

  /**
   * deleteUserBackups
   * ? Удалить один или множество (бэкапов / архивов) пользователя
   *
   * @param {IBackupDeletePayload} params параметры запроса (бэкапов / архивов)
   * @returns {Promise<void | AxiosResponse<TBackupErrorResponse>>} asdf
   */
  deleteUserBackups(
    params: IBackupDeletePayload
  ): Promise<void | AxiosResponse<TBackupErrorResponse>> {
    const isRoleAdmin = store.getters['account/isRoleAdmin']
    const isRoleDevOps = store.getters['account/isRoleDevOps']
    const config = {
      params,
      withCredentials: isRoleAdmin,
    }

    if (isRoleAdmin || isRoleDevOps) {
      Cookies.set(COOKIE_NAMES.ACCOUNT_ID, params.account_id)
    }

    return axios
      .delete('/api/webrestore/v1/backups', config)
      .then((response) => {
        if (ApiDeleteBackupError.hasBackupErrors(response)) {
          throw new ApiDeleteBackupError(response)
        }

        return response
      })
  },
}
