import type { IExternalProductsState } from './types'
import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type {
  TLinkCompanyPayload,
  IExternalLicensesPayload,
  IExternalProductResponse,
  TAssignExternalLicensePayload,
  IExternalLicensesActivateTrialPayload,
} from '@/api/types'

import api from '@/api'
import i18n from '@/locale'
import { App } from '@/main'

type TExternalProductsTree = ActionTree<IExternalProductsState, RootState>
type TExternalProductsContext = ActionContext<IExternalProductsState, RootState>

export const actions: TExternalProductsTree = {
  /**
   * fetchExternalLicenses
   * ? Извлечь список внешних лицензий
   *
   * @param {TExternalProductsContext} ctx context
   * @param {IExternalLicensesPayload} payload параметры запроса внешних лицензий
   * @returns {Promise<void>}
   */
  fetchExternalLicenses: (
    { commit }: TExternalProductsContext,
    payload: IExternalLicensesPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'external-licenses' })

    return api.lk
      .getAllExternalLicenses(payload)
      .then(({ data, meta }) => {
        commit('SET_EXTERNAL_LICENSES', data)
        commit('SET_EXTERNAL_LICENSES_META', meta)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'external-licenses',
        })
      })
  },

  assignLicense(
    { commit, rootGetters }: TExternalProductsContext,
    payload: TAssignExternalLicensePayload
  ): Promise<void> {
    if (
      rootGetters['account/isRoleBusinessUser'] === false &&
      rootGetters['account/userRole'] !== null
    ) {
      App.$notify({
        type: 'info',
        message: i18n
          .t(
            'component.notify.message-error-invalid-account-form-assign-license'
          )
          .toString(),
      })
      return Promise.resolve()
    }

    const linkCompanyPayload: TLinkCompanyPayload = {
      company_id: payload.company_id,
      token: payload.token,
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'assign-external-license',
    })

    return (
      api.lk
        // Проверяем возможность привязки лицензии к аккаунту.
        .checkAssignExternalLicense(payload)
        // Привязываем аккаунт к компании
        .then(() => api.lk.linkCompany(payload.account_id, linkCompanyPayload))
        // Привязываем лицензии к аккаунту
        .then(() => api.lk.assignExternalLicense(payload))
        .catch(() => {
          App.$notify({
            type: 'error',
            message: i18n
              .t('component.notify.message-assign-license-error')
              .toString(),
          })
        })
        .finally(() =>
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'assign-external-license',
          })
        )
    )
  },

  /**
   * fetchExternalProduct
   * ? Извлечь продукт по его идентификатору
   *
   * @param {TExternalProductsContext} ctx context
   * @param {string} id идентификатор продукта
   * @returns {Promise<IExternalProductResponse>} продукт
   */
  fetchExternalProduct: (
    { commit }: TExternalProductsContext,
    id: string
  ): Promise<IExternalProductResponse> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'external-product' })

    return api.lk.getExternalProduct(id).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'external-product',
      })
    })
  },

  /**
   * activateTrial
   * ? Активировать триальную лицензию
   *
   * @param {TExternalProductsContext} ctx context
   * @param {IExternalLicensesActivateTrialPayload} payload параметры на активацию пробной версии
   * @returns {Promise<void>}
   */
  activateTrial: async (
    { commit, dispatch }: TExternalProductsContext,
    payload: IExternalLicensesActivateTrialPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'activate-trial-license',
    })

    return api.lk
      .activateTrialLicense(payload)
      .then(() => dispatch('fetchExternalLicenses'))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'activate-trial-license',
        })
      })
  },
}
