import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IErrorResponse,
  IWrBackupPayload,
  IWrBackupDownloadFileData,
} from '@/api/types'
import type {
  ArchiveState,
  IDirectoryArchiveOptions,
  IArchiveOperationErrorPayload,
} from '@/store/modules/client/archive/types'

type ArchiveTree = ActionTree<ArchiveState, RootState>
type ArchiveContext = ActionContext<ArchiveState, RootState>

import api from '@/api'
import { saveAs } from 'file-saver'
import { replaceRoute } from '@/helpers/router'
import { EOperationState } from '@/config/enums'
import { DELAY, API_ERROR } from '@/config/constants'

const getEncodedPath = (path: string): string =>
  path.split('/').map(encodeURIComponent).join('/')

export const actions: ArchiveTree = {
  /**
   * fetchCurrentArchive
   * ? Извлечь текущий архив
   *
   * @param {ArchiveContext} ctx context
   * @param {string} name имя архива
   * @returns {Promise<void>}
   */
  fetchCurrentArchive: ({ commit }: ArchiveContext, name): Promise<void> => {
    const payload = { name }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'current-archive',
    })

    return api.webrestore
      .getArchives(payload)
      .then(({ data }) => commit('SET_CURRENT_ARCHIVE', data[0]))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'current-archive',
        })
      })
  },

  /**
   * fetchCurrentArchiveVersions
   * ? Извлечь список версий текущего архива
   *
   * @param {ArchiveContext} ctx context
   * @param {string} name имя архива
   * @returns {Promise<void>}
   */
  fetchCurrentArchiveVersions: (
    { commit, getters, rootGetters }: ArchiveContext,
    name
  ): Promise<void> => {
    const payload = { backup: name }
    const archiveVersionMap = rootGetters['archives/archiveVersionMap']

    if (getters.hasCurrentArchiveVersion(name)) {
      commit('SET_CURRENT_ARCHIVE_VERSIONS', archiveVersionMap[name])

      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'current-archive-versions',
    })

    return api.webrestore
      .getBackupVersions(payload)
      .then(({ data }) => commit('SET_CURRENT_ARCHIVE_VERSIONS', data))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'current-archive-versions',
        })
      })
  },

  /**
   * При получении ошибки OPERATION_NOT_FOUND
   * - удаляем существующий id операции
   * - заново запускаем создание новой операции и извлечения элементов архива
   *
   * @param {ArchiveContext} ctx context
   * @param {IArchiveOperationErrorPayload} payload данные ошибки
   * @returns {Promise<IErrorResponse>} ошибка запроса
   */
  handleArchiveItemError: (
    { commit, dispatch }: ArchiveContext,
    {
      error,
      options,
      callback = () => undefined,
    }: IArchiveOperationErrorPayload
  ): Promise<IErrorResponse> => {
    /**
     * При получении ошибки OPERATION_NOT_FOUND
     * - удаляем существующий id операции
     * - заново запускаем создание новой операции и извлечения элементов архива
     */
    if (API_ERROR.OPERATION_NOT_FOUND === error.code) {
      commit('operation/DELETE_OPERATION_ID', null, { root: true })
      dispatch('fetchFirstDirectoryBackupItems', options)
    }

    callback()

    return Promise.reject(error)
  },

  /**
   * fetchFirstDirectoryArchiveItems
   * ? Извлечь первую директорию с элементами бэкапа
   *
   * @param {ArchiveContext} ctx context
   * @param {IDirectoryArchiveOptions} options параметры запроса
   * @returns {Promise<void>}
   */
  fetchFirstDirectoryArchiveItems: (
    { commit, dispatch, getters, rootGetters }: ArchiveContext,
    options: IDirectoryArchiveOptions
  ): Promise<void> => {
    const { defaultArchiveMeta } = getters
    const { path, operation, params } = options

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'first-directory-archive-items',
    })

    const fetchArchiveItem = async (): Promise<void> => {
      /**
       * Проверяем, если ли id операции общего состояния
       * - Если нет, необходимо запросить
       * - Если да, используем тот, который был записан ранее в операциях общего состояния
       */
      const operationId = rootGetters['operation/hasOperationId']
        ? rootGetters['operation/operationId']
        : await dispatch('operation/createOperation', operation, {
            root: true,
          }).catch((error) =>
            dispatch('handleArchiveItemError', { error, options })
          )

      const payload = { ...params, operation_id: operationId }

      return api.webrestore.getBackupItem(payload).then((response) => {
        const view = getters.currentView
        const { data, meta, status } = response

        commit('SET_TREE_DIRECTORY_PATH', path.split('/').slice(2))
        commit('SET_ARCHIVE_DIRECTORY_TREE_META', {
          [path]: { ...defaultArchiveMeta, ...meta },
        })
        commit('SET_ARCHIVE_DIRECTORY_TREE', {
          [path]: {
            status,
            id: operationId,
            items: data ?? [],
          },
        })

        replaceRoute({
          path: `/archive/${getEncodedPath(path)}`,
          query: { view },
        })

        if (status === EOperationState.PROGRESS) {
          return new Promise<void>((resolve) =>
            commit(
              'operation/SET_OPERATION_TIMEOUT_ID',
              setTimeout(resolve, DELAY.WEB_RESTORE_OPERATION),
              { root: true }
            )
          ).then(() => fetchArchiveItem())
        }

        return Promise.resolve()
      })
    }

    return fetchArchiveItem()
      .catch((error) => dispatch('handleArchiveItemError', { error, options }))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'first-directory-archive-items',
        })
      )
  },

  /**
   * fetchNextDirectoryArchiveItems
   * ? Извлечь все последующие (выбранные) директории с элементами бэкапа
   *
   * @param {ArchiveContext} ctx context
   * @param {IDirectoryArchiveOptions} options параметры запроса и результирующие данные
   * @returns {Promise<void>}
   */
  fetchNextDirectoryArchiveItems: async (
    { commit, dispatch, getters, rootGetters }: ArchiveContext,
    options: IDirectoryArchiveOptions
  ): Promise<void> => {
    const view = getters.currentView
    const { archiveTree, archiveTreeMeta, defaultArchiveMeta } = getters
    const { params, path, operation, isSorted, isLoadMore } = options
    const { status } = archiveTree[path] ?? {}

    /**
     * При переходе на другую директорию, очищаем таймаут, чтобы избежать повторных вызовов для предыдущей директории, если она имела статус EOperationState.PROGRESS
     */
    clearTimeout(rootGetters['operation/operationTimeoutId'])

    if (!isSorted && !isLoadMore && status === EOperationState.DONE) {
      replaceRoute({
        path: `/archive/${getEncodedPath(path)}`,
        query: { view },
      })
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'next-directory-archive-items',
    })

    const fetchArchiveItem = async (): Promise<void> => {
      /**
       * Проверяем, если ли за кэшированный id операции для "конкретной" директории
       * - Если нет, необходимо запросить
       * - Если да, используем которые был записан ранее
       */
      const { id: cachedOperationId } = archiveTree[path] ?? {}
      const operationId = cachedOperationId
        ? cachedOperationId
        : await dispatch('operation/createOperation', operation, {
            root: true,
          }).catch((error) =>
            dispatch('handleArchiveItemError', { error, options })
          )
      const payload = { ...params, operation_id: operationId }

      return api.webrestore.getBackupItem(payload).then((response) => {
        const { data, meta, status } = response
        const { order_by, direction } = params
        const newArchiveItems = data ?? []
        const currentArchiveItems = archiveTree[path]?.items ?? []
        const currentArchiveItemsLength = currentArchiveItems.length
        const formedArchiveItems = currentArchiveItems.toSpliced(
          currentArchiveItemsLength,
          newArchiveItems.length,
          ...newArchiveItems
        )

        commit('UPDATE_ARCHIVE_DIRECTORY_TREE', {
          path,
          archiveOperation: {
            status,
            id: operationId,
            items: isLoadMore ? formedArchiveItems : newArchiveItems,
          },
        })

        commit('UPDATE_ARCHIVE_DIRECTORY_TREE_META', {
          path,
          meta: isSorted
            ? { ...archiveTreeMeta[path], order_by, direction }
            : {
                ...defaultArchiveMeta,
                ...meta,
                order_by,
                direction,
              },
        })

        replaceRoute({
          path: `/archive/${getEncodedPath(path)}`,
          query: { view },
        })

        if (status === EOperationState.PROGRESS) {
          return new Promise<void>((resolve) =>
            commit(
              'operation/SET_OPERATION_TIMEOUT_ID',
              setTimeout(resolve, DELAY.WEB_RESTORE_OPERATION),
              { root: true }
            )
          ).then(() => fetchArchiveItem())
        }

        return Promise.resolve()
      })
    }

    return fetchArchiveItem()
      .catch((error) =>
        dispatch('handleArchiveItemError', {
          error,
          options,
          callback: () => {
            clearTimeout(rootGetters['operation/operationTimeoutId'])
            replaceRoute({
              path: `/archive/${getEncodedPath(path)}`,
              query: { view },
            })
          },
        })
      )
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'next-directory-archive-items',
        })
      )
  },

  /**
   * downloadCurrentArchiveFile
   * ? Скачать текущий файл архива
   *
   * @param {ArchiveContext} ctx context
   * @param {IWrBackupDownloadFileData} data параметры запроса
   * @returns {Promise<void>}
   */
  downloadCurrentArchiveFile: (
    { commit }: ArchiveContext,
    data: IWrBackupDownloadFileData
  ): Promise<void> => {
    const { name, query } = data
    const payload = { query }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      groupId: query,
      name: 'current-archive-file-download',
    })

    return api.webrestore
      .downloadWrBackupFile(payload)
      .then((blob) => saveAs(blob, name))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          groupId: query,
          name: 'current-archive-file-download',
        })
      )
  },

  /**
   * fetchUrlArchiveImage
   * ? Извлечь текущий путь изображения архива
   *
   * @param {ArchiveContext} ctx context
   * @param {IWrBackupPayload} payload параметры запроса
   * @returns {Promise<string>} url изображения
   */
  fetchUrlArchiveImage: (
    { commit }: ArchiveContext,
    payload: IWrBackupPayload
  ): Promise<string> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'url-archive-image',
    })

    return api.webrestore
      .downloadWrBackupFile(payload)
      .then((blob) => URL.createObjectURL(blob))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'url-archive-image',
        })
      )
  },
}
