import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { TExternalLicenses } from '@/api/types'
import type { IExternalProductsState } from '@/store/modules/business/external-products/types'

type TExternalProductsTree = GetterTree<IExternalProductsState, RootState>

export const getters: TExternalProductsTree = {
  /**
   * externalLicenses
   * ? Список внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @returns {TExternalLicenses} список внешних лицензий
   */
  externalLicenses: (state: IExternalProductsState): TExternalLicenses => {
    return state.externalLicenses
  },

  /**
   * hasExternalLicenses
   * ? Признак наличия внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} признак наличия внешних лицензий
   */
  hasExternalLicenses: (state: IExternalProductsState): boolean => {
    return state.externalLicenses.length > 0
  },

  /**
   * hasTrialExternalLicense
   * ? Признак наличия пробной внешней лицензии
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} признак наличия пробной внешней лицензии
   */
  hasTrialExternalLicense: (state: IExternalProductsState): boolean => {
    return state.externalLicenses.some((item) => item.is_trial)
  },

  /**
   * isLoadingExternalLicenses
   * ? Процесс загрузки внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс загрузки внешних лицензий
   */
  isLoadingExternalLicenses: (state: IExternalProductsState): boolean => {
    return state.isLoadingExternalLicenses
  },

  /**
   * isLoadingAssignExternalLicense
   * ? Процесс привязки лицензии к аккаунту
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс привязки лицензии к аккаунту
   */
  isLoadingAssignExternalLicense: (state: IExternalProductsState): boolean => {
    return state.isLoadingAssignExternalLicense
  },

  /**
   * isLoadingExternalProduct
   * ? Процесс загрузки внешнего продукта
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс загрузки внешнего продукта
   */
  isLoadingExternalProduct: (state: IExternalProductsState): boolean => {
    return state.isLoadingExternalProduct
  },

  /**
   * isLoadingActivateTrialLicense
   * ? Процесс активации пробной лицензии
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс активации пробной лицензии
   */
  isLoadingActivateTrialLicense: (state: IExternalProductsState): boolean => {
    return state.isLoadingActivateTrialLicense
  },
}
