import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { IBackupDeletePayload } from '@/api/types'
import type {
  BackupsState,
  BackupsLocalMeta,
} from '@/store/modules/client/backups/types'
type BackupsTree = ActionTree<BackupsState, RootState>
type BackupsContext = ActionContext<BackupsState, RootState>

import api from '@/api'

export const actions: BackupsTree = {
  /**
   * fetchBackups
   * ? Извлечь список резервных копий
   *
   * @param {BackupsContext} ctx context
   * @param {BackupsLocalMeta} params параметры запроса
   * @returns {Promise<void>}
   */
  fetchBackups: (
    { commit, dispatch }: BackupsContext,
    params: BackupsLocalMeta
  ): Promise<void> => {
    commit('SET_BACKUPS_CURRENT_META', params)
    commit('SET_LOADING_PROCESS', { loading: true, name: 'backups' })

    return api.webrestore
      .getBackups(params)
      .then(({ data, meta }) => {
        commit('SET_BACKUPS', data)
        commit('SET_BACKUPS_CURRENT_META', meta)

        // Загрузка версий не входит в завершающую цепочку загрузки бэкапов
        Promise.all(
          data.map(({ name }) => dispatch('fetchBackupVersions', name))
        )
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'backups',
        })
      })
  },

  /**
   * fetchBackupVersions
   * ? Извлечь список версий резервных копий
   *
   * @param {BackupsContext} ctx context
   * @param {string} name название бэкапа
   * @returns {Promise<void>}
   */
  fetchBackupVersions: (
    { commit }: BackupsContext,
    name: string
  ): Promise<void> => {
    const payload = { backup: name }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      groupId: name,
      name: 'backup-versions',
    })

    return api.webrestore
      .getBackupVersions(payload)
      .then(({ data }) =>
        commit('SET_BACKUP_VERSIONS', { name, versions: data })
      )
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          groupId: name,
          name: 'backup-versions',
        })
      })
  },

  /**
   * deleteBackup
   * ? Удалить резервную копию
   *
   * @param {BackupsContext} param0 context
   * @param {IBackupDeletePayload} payload параметры запроса удаления архива
   * @returns {Promise<void>}
   */
  deleteBackup: (
    { commit, dispatch, getters }: BackupsContext,
    payload: IBackupDeletePayload
  ): Promise<void> => {
    const { page, order_by, direction } = getters.currentBackupsMeta
    const params = {
      page,
      order_by,
      direction,
    }

    commit('SET_LOADING_PROCESS', {
      name: 'backup-delete',
      loading: true,
    })

    return api.webrestore
      .deleteUserBackups(payload)
      .then(() => dispatch('fetchBackups', params))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          name: 'backup-delete',
          loading: false,
        })
      )
  },
}
