import type { NavState, NavModule } from '@/store/modules/nav/types'

import { getters } from '@/store/modules/nav/getters'
import { actions } from '@/store/modules/nav/actions'
import { mutations } from '@/store/modules/nav/mutations'

const state: NavState = {
  route: {},
  urlWebRestore: '',
  isNavVisible: false,
  isNavFullyOpen: false,
  isNewWebrestore: false,
  homePages: {
    lk_business_user: {
      name: 'business-products',
      path: '/business-products',
      pages: [
        'business-profile',
        'business-products',
        'assign_business_license',
      ],
    },
    lk_user: {
      name: 'products',
      path: '/products',
      pages: [
        'products',
        'profile',
        'backups',
        'backup',
        'archives',
        'archive',
        'assign_business_license',
      ],
    },
    lk_admin: {
      name: 'users',
      path: '/users',
      pages: [
        'skus',
        'users',
        'orders',
        'licenses',
        'campaigns',
        'user',
        'order',
        'license',
        'campaign',
      ],
    },
    lk_support: {
      name: 'users',
      path: '/users',
      pages: ['users', 'user', 'licenses', 'license'],
    },
    lk_viewer: {
      name: 'users',
      path: '/users',
      pages: ['users', 'user', 'licenses', 'license'],
    },
    lk_devops: {
      name: 'users',
      path: '/users',
      pages: ['users'],
    },
  },
  pageTitles: {
    default: 'component.header.title.undefined',
    profile: 'component.header.title.profile',
    support: 'component.header.title.support',
    products: 'component.header.title.products',
    backup: 'component.header.title.backups',
    backups: 'component.header.title.backups',
    archive: 'component.header.title.archives',
    archives: 'component.header.title.archives',
    user: 'component.header.title.users',
    users: 'component.header.title.users',
    license: 'component.header.title.licenses',
    licenses: 'component.header.title.licenses',
    order: 'component.header.title.orders',
    orders: 'component.header.title.orders',
    skus: 'component.header.title.skus',
    sku: 'component.header.title.skus',
    campaign: 'component.header.title.campaigns',
    campaigns: 'component.header.title.campaigns',
    'business-products': 'component.header.title.products',
    'business-profile': 'component.header.title.profile',
  },
}

export const nav: NavModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
