export const ru = {
  /**
   * ? Product name
   */
  'product.name.cyber-backup': 'Кибер Бэкап',
  'product.edition.advanced': 'Расширенная редакция',
  'product.edition.standard': 'Стандартная редакция',

  // ? Words
  'word.search': 'Поиск',

  // ? Dictionary
  'dictionary.key-count':
    '0 ключей | {count} ключ | {count} ключа | {count} ключей',

  // ? Modals
  'modal.download-product.header.title': 'Скачать дистрибутив: {name}',

  /**
   * ? COMPONENT: Notify
   */
  'component.notify.message-success.company-updated':
    'Изменены сведения о компании {name}',
  'component.notify.message-success.copy-serial-id': 'Серийный ключ скопирован',
  'component.notify.message-error.copy-serial-id':
    'Не удалось скопировать серийный ключ',

  /**
   * ? Edition staff
   */
  'edition.staff.name.mailbox': 'Почтовый ящик',
  'edition.staff.name.mailbox-5': 'Почтовый ящик • 5 адресов',
  'edition.staff.name.mailbox-25': 'Почтовый ящик • 25 адресов',
  'edition.staff.name.mailbox-50': 'Почтовый ящик • 50 адресов',
  'edition.staff.name.mailbox-100': 'Почтовый ящик • 100 адресов',
  'edition.staff.name.mailbox-500': 'Почтовый ящик • 500 адресов',
  'edition.staff.name.mailbox-1000': 'Почтовый ящик • 1000 адресов',
  'edition.staff.name.workstation': 'Рабочая станция',
  'edition.staff.name.virtual-host': 'Виртуальный хост',
  'edition.staff.name.server': 'Сервер',
  'edition.staff.name.universal-license': 'Универсальная лицензия',
  'edition.staff.name.postgresql': 'PostgreSQL',

  /**
   * ? FIELDS: label
   */
  'form.field.label.inn': 'ИНН',
  'form.field.label.unp': 'УНП',
  'form.field.label.kpp': 'КПП',
  'form.field.label.ogrn': 'ОГРН',
  'form.field.label.oked': 'ОКЕД',
  'form.field.label.company': 'Компания',
  'form.field.label.main-company': 'Основная компания',
  'form.field.label.key': 'Ключ',
  'form.field.label.keys': 'Ключи',
  'form.field.label.generated': 'Сгенерирован',

  /**
   * ? Buttons: label
   */
  'button.action.browse-all': 'Просмотреть все',
  'button.action.download-keys': 'Скачать ключи',

  /**
   * ? FIELDS: validate
   */
  'form.field.validate.company-required': 'Выберите компанию.',

  /**
   * ? FIELDS: placeholder
   */
  'field.placeholder.select.search-company': 'Поиск по названию, ИНН и адресу',

  /**
   * ? COMPONENT: Tag
   */
  'component.tag.name.trial-license': 'Пробная версия',
  'component.tag.name.perpetual-license': 'Бессрочная',
  'component.tag.name.its-you': 'Это Вы',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.documentation': 'Документация',
  'component.button.name.buy-full-version': 'Купить полную версию',
  'component.button.name.buy': 'Купить',
  'component.button.name.download-distribution': 'Скачать дистрибутив',
  'component.button.name.close': 'Закрыть',

  /**
   * ? COMPONENT: Select
   */
  'component.select.search.title.company-example':
    'Пример поиска (выводится до 20 результатов):',
  'component.select.search.text.company-name':
    'ООО “Ромашка”, 1234567890, Санкт-Петербург',

  /**
   * ? COMPONENT: Table
   */
  'component.table.cell.unlimited': 'Без ограничений',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.try': 'Попробовать',
  'component.button.name.re-enter': 'Перезайти',
  'component.button.name.buy-key': 'Купить ключ',

  /**
   * ? COMPONENT: Popover
   */
  'component.popover.distribution.title':
    'Чтобы воспользоваться пробной версией',
  'component.popover.distribution.download': '1. Скачайте дистрибутив',
  'component.popover.distribution.install':
    '2. Установите продукт и активируйте пробную версию в продукте',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.products': 'Продукты',
  'component.header.title.undefined': 'Раздел не найден',

  /**
   * ? PAGE: Error
   */
  'page.errors.title.account-type-not-found': 'Не определен тип аккаунта',

  /**
   * ? PAGE: Products
   */
  'page.products.card.title.no-key': 'У вас еще нет ключа',
  'page.products.keys': 'Ключи продукта',
  'page.products.order-number': 'Номер заказа',
  'page.products.subscription-period': 'Срок подписки',
  'page.products.card.title.try-product': 'Вы хотите попробовать продукт',
  'page.products.card.text.perpetual-license':
    'Приобрести бессрочную лицензию или лицензии на основе подписки',
  'page.products.card.text.trial-version':
    'Выберите продукт и получите доступ к пробной полнофункциональной версии',

  /**
   * ? PAGE: Profile
   */
  'page.profile.button.remove-profile': 'Удалить профиль',
  'page.profile.edit-company.title': 'Изменение сведений о компании',
  'page.profile.not-specified': 'Не указано',
  'page.profile.company-unselected': 'Компания не выбрана',
}
