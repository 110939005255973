import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { OkDeskItemResponse, OkDeskListResponse } from '@/api/types'
import type {
  SupportState,
  SupportForm,
} from '@/store/modules/client/support/types'

import api from '@/api'
import {
  CYBER_BACKUP,
  CYBER_BACKUP_PERSONAL,
  SUPPORT_PROBLEM_TYPE,
} from '@/config/constants'

type SupportTree = ActionTree<SupportState, RootState>
type SupportContext = ActionContext<SupportState, RootState>

export const actions: SupportTree = {
  /**
   * createSupport
   * ? Создать обращение в техподдержку
   *
   * @param {ActionContext} param0 context
   * @param {object} formData форма поддержки
   * @returns {Promise<void>}
   */
  createSupport: async (
    { commit, dispatch, rootGetters }: SupportContext,
    formData
  ): Promise<void> => {
    commit('SET_LOADING', true)

    const isAccountMustBeUpdated =
      rootGetters['account/isAccountMustBeUpdated'](formData)

    // Только если данные изменились в процессе заполнения
    if (isAccountMustBeUpdated) {
      /**
       * Ждем обновления контактных данных пользователя, если они были изменены на шаге создания обращения в поддержку
       * 1. Обрабатывать запрос не нужно, т.к. все поля покрыты локальной валидацией.
       * 2. Можно ожидать только 500-й ошибки, она обрабатывается глобально со статическим модальным окном.
       */

      try {
        await dispatch('account/updateAccount', formData, { root: true })
      } catch {
        commit('SET_LOADING', false)
      }
    }

    /**
     * Если у аккаунта еще нет лицензий, то раздел с выбором проблем отсутствует.
     * По умолчанию устанавливаем - GENERAL_QUESTIONS (Другая проблема)
     */
    const type = formData.type?.value ?? SUPPORT_PROBLEM_TYPE.GENERAL_QUESTIONS
    const isProductType = type === SUPPORT_PROBLEM_TYPE.TECHNICAL_ISSUE
    const licenseID = rootGetters['products/lastExpiringLicenseID']
    const availableLicense = isProductType ? { license_id: licenseID } : {}
    const data: SupportForm = {
      ...availableLicense,
      issue_type: type,
      issue_title: formData.subject,
      issue_description: formData.description,
      account_id: rootGetters['account/userAccountID'],
      product_id: formData.product?.value,
      version_id: formData.productVersion?.value,
      platform_id: formData.platform?.value,
      priority: formData.priority?.value,
    }

    const payload = {
      header: JSON.stringify(data),
      file: [
        ...formData.files,
        ...(formData.certificate ?? []),
      ] as BinaryType[],
    }

    return api.lk
      .createSupport(payload)
      .finally(() => commit('SET_LOADING', false))
  },

  /**
   * fetchSupportList
   * ? Извлечь список обращений
   *
   * @param {ActionContext} param0 context
   * @returns {Promise<OkDeskListResponse>} исходный список обращений
   */
  fetchSupportList: ({
    commit,
  }: SupportContext): Promise<OkDeskListResponse> => {
    commit('SET_LOADING', true)

    return api.lk
      .getSupportList()
      .then((result) => {
        const supportList = (result || []).map((item) => {
          return {
            id: item.id ?? null,
            type: item.type ?? null,
            title: item.title ?? null,
            status: item.status ?? null,
            creationDate: item.creation_date ?? null,
          }
        })

        commit('SET_SUPPORT_LIST', supportList)

        return result
      })
      .finally(() => commit('SET_LOADING', false))
  },

  /**
   * fetchSupportItem
   * ? Извлечь детализированный объект обращения
   *
   * @param {ActionContext} param0 context
   * @param {string} id идентификатор
   * @returns {Promise<OkDeskItemResponse>} исходный детализированный объект обращения
   */
  fetchSupportItem: (
    { commit, rootGetters }: SupportContext,
    id: string
  ): Promise<OkDeskItemResponse> => {
    const userName = rootGetters['account/userName']
    const isBusinessUser = rootGetters['account/isRoleBusinessUser']
    const { phone, email } = rootGetters['account/userAccount']

    commit('SET_LOADING', true)

    return api.lk
      .getSupportItem(id)
      .then((item) => {
        const supportItem = {
          email,
          userName,
          phone: phone ?? '',
          id: item.id ?? null,
          type: item.type ?? null,
          title: item.title ?? null,
          status: item.status ?? null,
          product_id: isBusinessUser ? CYBER_BACKUP : CYBER_BACKUP_PERSONAL,
          attachments: item.attachments ?? [],
          description: item.description ?? null,
          creationDate: item.creation_date ?? null,
          modificationDate: item.modification_date ?? null,
        }

        commit('SET_SUPPORT_ITEM', supportItem)

        return item
      })
      .finally(() => commit('SET_LOADING', false))
  },
}
