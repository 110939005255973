import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IWrBackupItemData, IWrBackupItemResponse } from '@/api/types'
import type {
  BackupState,
  BackupLocalMeta,
  IDirectoryBackupMeta,
  IWrLocaleBackupContact,
  IDirectoryBackupOperation,
  IWrLocaleBackupItemResponse,
} from '@/store/modules/client/backup/types'

type BackupTree = GetterTree<BackupState, RootState>

import sumBy from 'lodash/sumBy'
import { compareAsc } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import {
  getWrIcons,
  ICON_TYPE_CONTENT,
  ICON_TYPE_CATEGORIES,
  MOBILE_CATEGORY_BACKUPS,
} from '@/helpers/wr-icons'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

const getContact = (
  data: IWrBackupItemData | undefined
): Partial<IWrLocaleBackupContact> => {
  if (data === undefined) {
    return {}
  }

  const phone = data.phones?.[0] ?? null
  const email = data.emails?.[0] ?? null

  return {
    phone,
    email,
    amountPhones: data.phones?.length ?? 0,
    amountEmails: data.emails?.length ?? 0,
    formatPhone: getFormattedSimpleValue(phone),
    formatEmail: getFormattedSimpleValue(email),
  }
}

export const getters: BackupTree = {
  /**
   * backupTree
   * ? Дерево директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {Partial<IDirectoryBackupOperation>} дерево директорий резервной копии
   */
  backupTree: (state: BackupState): Partial<IDirectoryBackupOperation> => {
    return state.backupTree
  },

  /**
   * backupTreeMeta
   * ? Дерево мета данных директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {Partial<IDirectoryBackupMeta>} дерево мета данных директорий резервной копии
   */
  backupTreeMeta: (state: BackupState): Partial<IDirectoryBackupMeta> => {
    return state.backupTreeMeta
  },

  /**
   * currentView
   * ? Текущий вид резервных копий
   *
   * @param {BackupState} state data
   * @returns {string} текущий вид резервных копий
   */
  currentView: (state: BackupState): string => {
    return state.currentView
  },

  /**
   * currentBackup
   * ? Текущая резервная копия
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {IWrLocaleBackupItemResponse | null} дерево мета данных директорий резервной копии
   */
  currentBackup: (
    state: BackupState,
    getters
  ): IWrLocaleBackupItemResponse | null => {
    if (state.currentBackup === null) {
      return null
    }

    const { size, subtype, created_at, display_name } = state.currentBackup

    return {
      ...state.currentBackup,
      icon: getWrIcons(subtype),
      formatSize: getFormattedSize(size),
      formatUpdatedItemDate: getFormattedDate(created_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(created_at, true),
      formatLastBackupSize: getFormattedSize(getters.lastBackupSize, 2, true),
      formatTotalBackupSize: getFormattedSize(getters.totalBackupSize),
      formatLastBackupCreatedDateTime: getFormattedDate(
        getters.lastBackupCreatedDate,
        true
      ),
    }
  },

  /**
   * totalBackupSize
   * ? Общий размер бэкапа
   *
   * @param {BackupState} state data
   * @returns {number} общий размер бэкапа
   */
  totalBackupSize: (state: BackupState): number => {
    return sumBy(state.currentBackupVersions ?? [], 'size')
  },

  /**
   * lastBackupSize
   * ? Размер последнего бэкапа
   *
   * @param {BackupState} state data
   * @returns {number} размер последнего бэкапа
   */
  lastBackupSize: (state: BackupState): number => {
    const versions = state.currentBackupVersions

    return Array.isArray(versions) && versions.length > 0
      ? versions[versions.length - 1].size
      : 0
  },

  /**
   * lastBackupCreatedDate
   * ? Дата создания последнего бэкапа
   *
   * @param {BackupState} state data
   * @returns {string | null} дата создания последнего бэкапа
   */
  lastBackupCreatedDate: (state: BackupState): string | null => {
    const versions = state.currentBackupVersions

    return Array.isArray(versions) && versions.length > 0
      ? versions[versions.length - 1].created_at
      : null
  },

  /**
   * currentBackupDirectory
   * ? Текущая (выбранная) директория резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} текущая (выбранная) директория резервной копии
   */
  currentBackupDirectory: (
    state: BackupState,
    getters
  ): IWrBackupItemResponse[] => {
    const { currentBackupQueryPath } = getters

    if (!(currentBackupQueryPath in state.backupTree)) {
      return []
    }

    const backupItems = state.backupTree[currentBackupQueryPath]?.items ?? []

    return backupItems.map((backup) => {
      const { size, data, type, subtype, updated_at } = backup

      const iconType =
        type === 'dir' &&
        Object.values(MOBILE_CATEGORY_BACKUPS).includes(subtype)
          ? ICON_TYPE_CATEGORIES
          : ICON_TYPE_CONTENT

      return {
        ...backup,
        ...getContact(data),
        formatSize: getFormattedSize(size),
        icon: getWrIcons(subtype ?? type, iconType),
        formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      }
    })
  },

  /**
   * treeDirectoryPath
   * ? Список сформированных путей директорий дерева
   *
   * @param {BackupState} state data
   * @returns {Partial<string[]>} список сформированных путей директорий дерева
   */
  treeDirectoryPath: (state: BackupState): Partial<string[]> => {
    return state.treeDirectoryPath
  },

  /**
   * currentFolder
   * ? Имя текущей директории (папки) резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей директории (папки) резервной копии
   */
  currentFolder: (state: BackupState): string => {
    return state.currentFolder
  },

  /**
   * currentBackupName
   * ? Имя текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей резервной копии
   */
  currentBackupName: (state: BackupState): string => {
    return state.currentBackupName
  },

  /**
   * currentDecodedBackupName
   * ? Декодированное имя текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей резервной копии
   */
  currentDecodedBackupName: (state: BackupState): string => {
    const [name] = decodeURIComponent(state.currentBackupName).split('/')

    return name
  },

  /**
   * currentBackupSubtype
   * ? Тип текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} тип текущей резервной копии
   */
  currentBackupSubtype: (state: BackupState): string => {
    return state.currentBackupSubtype
  },

  /**
   * currentBackupVersion
   * ? Текущая версия резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} текущая версия резервной копии
   */
  currentBackupVersion: (state: BackupState): string => {
    return state.currentBackupVersion
  },

  /**
   * currentBackupQueryPath
   * ? Текущий выбранный путь запроса получения нужной директории
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {string} текущий выбранный путь запроса получения нужной директории
   */
  currentBackupQueryPath: (state: BackupState, getters): string => {
    const { currentBackupName, currentBackupVersion, treeDirectoryPath } =
      getters

    return [currentBackupName, currentBackupVersion, ...treeDirectoryPath].join(
      '/'
    )
  },

  /**
   * currentBackupVersions
   * ? Список версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {IWrBackupItemResponse[]} список версий текущей резервной копий
   */
  currentBackupVersions: (state: BackupState): IWrBackupItemResponse[] => {
    return cloneDeep(state.currentBackupVersions).sort((a, b) => {
      return compareAsc(new Date(b.updated_at), new Date(a.updated_at))
    })
  },

  /**
   * defaultBackupMeta
   * ? Дефолтные мета данные содержимого бэкапа
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {BackupLocalMeta} дефолтные мета данные содержимого бэкапа
   */
  defaultBackupMeta: (state: BackupState, getters): BackupLocalMeta => {
    return {
      ...state.defaultBackupMeta,
      order_by:
        getters.isSubtypeBackupMobile &&
        (state.currentFolder === '' || getters.isBackupMobileCategoryContacts)
          ? 'name'
          : 'updated_at',
    }
  },

  /**
   * hasCurrentBackupVersion
   * ? Признак существования списка текущей резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {boolean} признак существования списка текущей резервной копии
   */
  hasCurrentBackupVersion: (
    state: BackupState,
    getters,
    rootState: RootState
  ): ((name: string) => boolean) => {
    return (name): boolean => {
      const { backups } = rootState

      if (!backups || Object.keys(backups.versions).length === 0) {
        return false
      }

      return name in backups.versions
    }
  },

  /**
   * isSubtypeBackupMobile
   * ? Признак мобильной резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} признак мобильной резервной копии
   */
  isSubtypeBackupMobile: (state: BackupState): boolean => {
    return state.currentBackupSubtype === 'mobile'
  },

  /**
   * isBackupMobileCategoryContacts
   * ? Признак мобильной резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} признак мобильной резервной копии
   */
  isBackupMobileCategoryContacts: (state: BackupState): boolean => {
    const [, contacts] = MOBILE_CATEGORY_BACKUPS.CONTACTS.split('/')

    return state.currentFolder === contacts
  },

  /**
   * isLoadingCurrentBackup
   * ? Процесс загрузки текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущей резервной копий
   */
  isLoadingCurrentBackup: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackup
  },

  /**
   * isLoadingUrlBackupImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlBackupImage: (state: BackupState): boolean => {
    return state.isLoadingUrlBackupImage
  },

  /**
   * isLoadingCurrentBackupVersions
   * ? Процесс загрузки списка версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки списка версий текущей резервной копий
   */
  isLoadingCurrentBackupVersions: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupVersions
  },

  /**
   * isLoadingFirstDirectoryBackupItems
   * ? Процесс загрузки первых директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки первых директорий резервной копии
   */
  isLoadingFirstDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingFirstDirectoryBackupItems
  },

  /**
   * isLoadingNextDirectoryBackupItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingNextDirectoryBackupItems
  },
}
