import type {
  BackupState,
  BackupModule,
} from '@/store/modules/client/backup/types'

import { getters } from '@/store/modules/client/backup/getters'
import { actions } from '@/store/modules/client/backup/actions'
import { mutations } from '@/store/modules/client/backup/mutations'

const getBackupMeta = () => {
  return {
    page: 1,
    staticLimit: 100,
    direction: 'desc',
    order_by: '', // сортировка по ключу определяется в геттере defaultBackupMeta
  }
}

const state: BackupState = {
  currentView: '',
  currentFolder: '',
  currentBackupName: '',
  currentBackupSubtype: '',
  currentBackupVersion: '',

  backupTree: {},
  backupTreeMeta: {},
  currentBackup: null,
  treeDirectoryPath: [],
  currentBackupVersions: [],
  defaultBackupMeta: getBackupMeta(),

  isLoadingCurrentBackup: false,
  isLoadingUrlBackupImage: false,
  isLoadingCurrentBackupVersions: false,
  isLoadingNextDirectoryBackupItems: false,
  isLoadingFirstDirectoryBackupItems: false,
}

export const backup: BackupModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
