<template>
  <span
    class="vpc-icon-container"
    :style="{
      '--width': `${iconWidth}px`,
      '--height': `${iconHeight}px`,
    }"
    :title="title"
    v-on="$listeners"
  >
    <svg
      :style="{
        '--color': currentColor,
        '--url': `url(${require(`@/assets/img/svg/${name}.svg`)})`,
      }"
      :class="{
        'vpc-icon': true,
        'vpc-icon_mask': !!currentColor,
      }"
    />
  </span>
</template>

<script>
import style from '@/assets/styles/_export-var.module.scss'

export default {
  name: 'VpcIcon',

  props: {
    // Название иконки
    name: {
      type: String,
      required: true,
    },

    // Цвет иконки.
    color: {
      type: String,
      default: '',
    },

    // Ширина иконки в пикселях
    width: {
      type: [String, Number],
      default: 16,
    },

    // Высота иконки в пикселях
    height: {
      type: [String, Number],
      default: 16,
    },

    // Размер иконки в пикселях
    size: {
      type: [String, Number],
      default: 0,
    },

    title: {
      type: String,
      default: '',
    },
  },

  computed: {
    iconWidth() {
      return this.size ? this.size : this.width
    },

    iconHeight() {
      return this.size ? this.size : this.height
    },

    currentColor() {
      // Иконки начинающиеся на clr и wr имеют свой цвет который не нужно переопределять в стилях
      if (this.name.startsWith('clr-') || this.name.startsWith('wr-')) {
        return null
      }

      if (this.color !== '') {
        return this.color
      }

      return this.$parent.$options._componentTag === 'el-button'
        ? 'currentColor'
        : style.colorBlackLightest
    },
  },
}
</script>

<style lang="scss">
$color: var(--color);
$url: var(--url);
$width: var(--width);
$height: var(--height);

.vpc-icon-container {
  width: $width;
  height: $height;
  min-width: $width;
  display: inline-flex;
}

.vpc-icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: $url;

  width: 100%;
  height: 100%;

  overflow: hidden;
  border: none;

  &_mask {
    background-color: $color;
    background-image: none;

    mask-image: $url;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: contain;
  }
}
</style>
