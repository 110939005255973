import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IWrBackupItemResponse } from '@/api/types'
import type {
  ArchiveState,
  ArchiveLocalMeta,
  IDirectoryArchiveMeta,
  IDirectoryArchiveOperation,
  IWrLocaleArchiveItemResponse,
} from '@/store/modules/client/archive/types'

type ArchiveTree = GetterTree<ArchiveState, RootState>

import sumBy from 'lodash/sumBy'
import { compareAsc } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { getWrIcons } from '@/helpers/wr-icons'
import {
  getFormattedDate,
  getFormattedSize,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchiveTree = {
  /**
   * archiveDisplayName
   * ? Отображаемое имя архива
   *
   * @param {ArchiveState} state data
   * @returns {string} отображаемое имя архива
   */
  archiveDisplayName: (state: ArchiveState): string => {
    return state.currentArchive?.display_name ?? ''
  },

  /**
   * currentArchive
   * ? Содержимое текущего архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {IWrLocaleArchiveItemResponse | null } содержимое текущего архива
   */
  currentArchive: (
    state: ArchiveState,
    getters
  ): IWrLocaleArchiveItemResponse | null => {
    if (state.currentArchive === null) {
      return null
    }

    const { size, subtype, updated_at, display_name } = state.currentArchive

    return {
      ...state.currentArchive,
      icon: getWrIcons(subtype),
      formatSize: getFormattedSize(size),
      formatUpdatedItemDate: getFormattedDate(updated_at),
      formatDisplayName: getFormattedSimpleValue(display_name),
      formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      formatTotalArchiveSize: getFormattedSize(getters.totalArchiveSize),
      formatCreatedArchiveDateTime: getFormattedDate(
        getters.lastArchiveCreatedDate,
        true
      ),
    }
  },

  /**
   * totalArchiveSize
   * ? Общий размер архива
   *
   * @param {ArchiveState} state data
   * @returns {number} общий размер архива
   */
  totalArchiveSize: (state: ArchiveState): number => {
    const archiveVersion = state.currentArchiveVersions

    return sumBy(archiveVersion ?? [], 'size')
  },

  /**
   * lastArchiveCreatedDate
   * ? Дата создания последнего архива
   *
   * @param {ArchiveState} state data
   * @returns {string | null} дата создания последнего архива
   */
  lastArchiveCreatedDate: (state: ArchiveState): string | null => {
    const archiveVersion = state.currentArchiveVersions

    return archiveVersion === undefined
      ? null
      : archiveVersion[archiveVersion.length - 1].created_at
  },

  /**
   * archiveTree
   * ? Дерево директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {Partial<IDirectoryArchiveOperation>} дерево директорий архива
   */
  archiveTree: (state: ArchiveState): Partial<IDirectoryArchiveOperation> => {
    return state.archiveTree
  },

  /**
   * archiveTreeMeta
   * ? Дерево мета данных директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {Partial<IDirectoryArchiveMeta>} дерево мета данных директорий архива
   */
  archiveTreeMeta: (state: ArchiveState): Partial<IDirectoryArchiveMeta> => {
    return state.archiveTreeMeta
  },

  /**
   * currentView
   * ? Текущий вид архивов
   *
   * @param {ArchiveState} state data
   * @returns {string} текущий вид архивов
   */
  currentView: (state: ArchiveState): string => {
    return state.currentView
  },

  /**
   * currentArchiveDirectory
   * ? Текущая (выбранная) директория архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} текущая (выбранная) директория архива
   */
  currentArchiveDirectory: (
    state: ArchiveState,
    getters
  ): IWrBackupItemResponse[] => {
    const { currentArchiveQueryPath } = getters

    if (!(currentArchiveQueryPath in state.archiveTree)) {
      return []
    }

    const archiveItems = state.archiveTree[currentArchiveQueryPath]?.items ?? []

    return archiveItems.map((archive) => {
      const { size, type, subtype, updated_at } = archive

      return {
        ...archive,
        icon: getWrIcons(subtype ?? type),
        formatSize: getFormattedSize(size),
        formatUpdatedItemDateTime: getFormattedDate(updated_at, true),
      }
    })
  },

  /**
   * treeDirectoryPath
   * ? Список сформированных путей директорий дерева
   *
   * @param {ArchiveState} state data
   * @returns {Partial<string[]>} список сформированных путей директорий дерева
   */
  treeDirectoryPath: (state: ArchiveState): Partial<string[]> => {
    return state.treeDirectoryPath
  },

  /**
   * latestArchiveVersion
   * ? Последняя версия архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {IWrBackupItemResponse | null} Последняя версия архива
   */
  latestArchiveVersion:
    (
      state: ArchiveState,
      getters,
      rootState: RootState
    ): ((archiveName: string) => IWrBackupItemResponse | null) =>
    (archiveName: string): IWrBackupItemResponse | null => {
      const { archives } = rootState

      const currentArchiveVersions =
        Object.keys(archives?.archiveVersionMap ?? {}).length > 0
          ? archives?.archiveVersionMap[archiveName] ?? []
          : state.currentArchiveVersions
      const currentArchiveVersionNumbers = currentArchiveVersions.map(
        ({ name }) => Number(name)
      )

      const latestArchiveVersionNumber = Math.max(
        ...currentArchiveVersionNumbers
      )

      return (
        currentArchiveVersions.find(
          ({ name }) => name === String(latestArchiveVersionNumber)
        ) ?? null
      )
    },

  /**
   * hasCurrentArchiveVersion
   * ? Признак существования списка текущего архива
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {Function} признак существования списка текущего архива
   */
  hasCurrentArchiveVersion: (
    state: ArchiveState,
    getters,
    rootState: RootState
  ): ((name: string) => boolean) => {
    return (name): boolean => {
      const { archives } = rootState

      if (!archives || Object.keys(archives.archiveVersionMap).length === 0) {
        return false
      }

      return name in archives.archiveVersionMap
    }
  },

  /**
   * currentArchiveQueryPath
   * ? Текущий выбранный путь запроса получения нужной директории
   *
   * @param {ArchiveState} state data
   * @param {object} getters computed
   * @returns {string} текущий выбранный путь запроса получения нужной директории
   */
  currentArchiveQueryPath: (state: ArchiveState, getters): string => {
    const name = state.currentArchiveName
    const { latestArchiveVersion, treeDirectoryPath } = getters
    const { name: version } = latestArchiveVersion(name)

    return [name, version, ...treeDirectoryPath].join('/')
  },

  /**
   * currentArchiveVersions
   * ? Список версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {IWrBackupItemResponse[]} список версий текущего архива
   */
  currentArchiveVersions: (state: ArchiveState): IWrBackupItemResponse[] => {
    return cloneDeep(state.currentArchiveVersions).sort((a, b) => {
      return compareAsc(b.updated_at, a.updated_at)
    })
  },

  /**
   * defaultArchiveMeta
   * ? Дефолтные мета данные архива
   *
   * @param {ArchiveState} state data
   * @returns {ArchiveLocalMeta} дефолтные мета данные архива
   */
  defaultArchiveMeta: (state: ArchiveState): ArchiveLocalMeta => {
    return state.defaultArchiveMeta
  },

  /**
   * isLoadingCurrentArchive
   * ? Процесс загрузки текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего архива
   */
  isLoadingCurrentArchive: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchive
  },

  /**
   * isLoadingUrlArchiveImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlArchiveImage: (state: ArchiveState): boolean => {
    return state.isLoadingUrlArchiveImage
  },

  /**
   * isLoadingCurrentArchiveVersions
   * ? Процесс загрузки списка версий текущего архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки списка версий текущего архива
   */
  isLoadingCurrentArchiveVersions: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveVersions
  },

  /**
   * isLoadingFirstDirectoryArchiveItems
   * ? Процесс загрузки первых директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки первых директорий архива
   */
  isLoadingFirstDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingFirstDirectoryArchiveItems
  },

  /**
   * isLoadingNextDirectoryArchiveItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryArchiveItems: (state: ArchiveState): boolean => {
    return state.isLoadingNextDirectoryArchiveItems
  },

  /**
   * isLoadingCurrentArchiveFileDownload
   * ? Процесс скачивания выбранного файла архива
   *
   * @param {ArchiveState} state data
   * @returns {boolean} процесс скачивания выбранного файла архива
   */
  isLoadingCurrentArchiveFileDownload: (state: ArchiveState): boolean => {
    return state.isLoadingCurrentArchiveFileDownload
  },
}
