import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IArchivesState,
  IArchiveVersions,
  ArchivesLocalMeta,
} from '@/store/modules/client/archives/types'
import type { IWrBackupItemResponse } from '@/api/types'

type ArchivesTree = GetterTree<IArchivesState, RootState>

import sumBy from 'lodash/sumBy'
import { getWrIcons, ICON_TYPE_LIST } from '@/helpers/wr-icons'
import {
  getFormattedSize,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchivesTree = {
  /**
   * hasArchives
   * ? Признак наличия архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} признак наличия архивов
   */
  hasArchives: (state: IArchivesState): boolean => {
    return state.archives.length > 0
  },

  /**
   * archives
   * ? Список архивов
   *
   * @param {IArchivesState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} список архивов
   */
  archives: (state: IArchivesState, getters): IWrBackupItemResponse[] => {
    return state.archives.map((archive: IWrBackupItemResponse) => {
      const { name, subtype } = archive
      const [decodedName] = decodeURIComponent(name).split('/')

      return {
        ...archive,
        icon: getWrIcons(subtype, ICON_TYPE_LIST),
        formatDisplayName: getFormattedSimpleValue(decodedName),
        formatTotalArchiveSize: getFormattedSize(
          getters.totalArchiveSize(name)
        ),
        formatCreatedArchiveDate: getFormattedDate(
          getters.lastArchiveCreatedDate(name)
        ),
        formatCreatedArchiveDateTime: getFormattedDate(
          getters.lastArchiveCreatedDate(name),
          true
        ),
      }
    })
  },

  /**
   * totalArchiveSize
   * ? Общий размер архива
   *
   * @param {IArchivesState} state data
   * @returns {Function} функция получение общего размера архива
   */
  totalArchiveSize: (state: IArchivesState): ((name: string) => number) => {
    return (name: string): number => {
      const archiveVersion = state.archiveVersionMap[name]

      return sumBy(archiveVersion ?? [], 'size')
    }
  },

  /**
   * lastArchiveCreatedDate
   * ? Дата создания последнего архива
   *
   * @param {IArchivesState} state data
   * @returns {Function} функция получение даты создания последнего архива
   */
  lastArchiveCreatedDate: (
    state: IArchivesState
  ): ((name: string) => string | null) => {
    return (name: string): string | null => {
      const archiveVersion = state.archiveVersionMap[name]

      return archiveVersion === undefined
        ? null
        : archiveVersion[archiveVersion.length - 1].created_at
    }
  },

  /**
   * archiveVersionMap
   * ? Карта списка архивов с содержимым всех имеющихся версий
   *
   * @param {IArchivesState} state data
   * @returns {IArchiveVersions} Карта списка архивов с содержимым всех имеющихся версий
   */
  archiveVersionMap: (state: IArchivesState): IArchiveVersions => {
    return state.archiveVersionMap
  },

  /**
   * currentArchiveVersion
   * ? Текущая версия архива
   *
   * @param {IArchivesState} state data
   * @returns {ArchivesLocalMeta} текущая версия архива
   */
  currentArchiveVersion:
    (state: IArchivesState): ((name: string) => number) =>
    (name: string): number => {
      const versions = (state.archiveVersionMap[name] || []).map(({ name }) =>
        Number(name)
      )

      return Math.max(...versions)
    },

  /**
   * currentArchivesMeta
   * ? Текущие мета списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {ArchivesLocalMeta} текущие мета списка архивов
   */
  currentArchivesMeta: (state: IArchivesState): ArchivesLocalMeta => {
    return state.currentArchivesMeta
  },

  /**
   * isLoadingArchives
   * ? Процесс загрузки списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки списка архивов
   */
  isLoadingArchives: (state: IArchivesState): boolean => {
    return state.isLoadingArchives
  },

  /**
   * isLoadingArchiveDelete
   * ? Процесс удаления архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс удаления архива
   */
  isLoadingArchiveDelete: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveDelete
  },

  /**
   * isLoadingArchiveVersions
   * ? Процесс загрузки версий архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки версий архива
   */
  isLoadingArchiveVersions: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveVersions
  },
}
