import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { IErrorResponse, IWrBackupPayload } from '@/api/types'
import type {
  BackupState,
  IDirectoryBackupOptions,
  IBackupOperationErrorPayload,
} from '@/store/modules/client/backup/types'

type BackupTree = ActionTree<BackupState, RootState>
type BackupContext = ActionContext<BackupState, RootState>

import api from '@/api'
import { replaceRoute } from '@/helpers/router'
import { EOperationState } from '@/config/enums'
import { API_ERROR, DELAY } from '@/config/constants'

const getEncodedPath = (path: string): string =>
  path.split('/').map(encodeURIComponent).join('/')

export const actions: BackupTree = {
  /**
   * fetchCurrentBackup
   * ? Извлечь текущую резервную копию
   *
   * @param {BackupContext} ctx context
   * @param {string} name имя резервной копии
   * @returns {Promise<void>}
   */
  fetchCurrentBackup: ({ commit }: BackupContext, name): Promise<void> => {
    const payload = { name }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'current-backup',
    })

    return api.webrestore
      .getBackups(payload)
      .then(({ data }) => commit('SET_CURRENT_BACKUP', data[0]))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'current-backup',
        })
      })
  },

  /**
   * fetchCurrentBackupVersions
   * ? Извлечь список версий текущий резервной копии
   *
   * @param {BackupContext} ctx context
   * @param {string} name имя бэкапа
   * @returns {Promise<void>}
   */
  fetchCurrentBackupVersions: (
    { commit, getters, rootGetters }: BackupContext,
    name
  ): Promise<void> => {
    const payload = { backup: name }
    const backupListVersions = rootGetters['backups/backupListVersions']
    const { currentBackupName, hasCurrentBackupVersion } = getters

    if (hasCurrentBackupVersion(currentBackupName)) {
      commit(
        'SET_CURRENT_BACKUP_VERSIONS',
        backupListVersions[currentBackupName]
      )

      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'current-backup-versions',
    })

    return api.webrestore
      .getBackupVersions(payload)
      .then(({ data }) => commit('SET_CURRENT_BACKUP_VERSIONS', data))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'current-backup-versions',
        })
      })
  },

  /**
   * При получении ошибки OPERATION_NOT_FOUND
   * - удаляем существующий id операции
   * - заново запускаем создание новой операции и извлечения элементов бэкапа
   *
   * @param {BackupContext} ctx context
   * @param {IBackupOperationErrorPayload} payload данные ошибки
   * @returns {Promise<IErrorResponse>} ошибка запроса
   */
  handleBackupItemError: (
    { commit, dispatch }: BackupContext,
    { error, options, callback = () => undefined }: IBackupOperationErrorPayload
  ): Promise<IErrorResponse> => {
    /**
     * При получении ошибки OPERATION_NOT_FOUND
     * - удаляем существующий id операции
     * - заново запускаем создание новой операции и извлечения элементов бэкапа
     */
    if (API_ERROR.OPERATION_NOT_FOUND === error.code) {
      commit('operation/DELETE_OPERATION_ID', null, { root: true })
      dispatch('fetchFirstDirectoryBackupItems', options)
    }

    callback()

    return Promise.reject(error)
  },

  /**
   * fetchFirstDirectoryBackupItems
   * ? Извлечь первую директорию с элементами бэкапа
   *
   * @param {BackupContext} ctx context
   * @param {IDirectoryBackupOptions} options параметры запроса
   * @returns {Promise<void>}
   */
  fetchFirstDirectoryBackupItems: (
    { commit, dispatch, getters, rootGetters }: BackupContext,
    options: IDirectoryBackupOptions
  ): Promise<void> => {
    const { path, subtype, operation, params } = options

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'first-directory-backup-items',
    })

    const fetchBackupItem = async (): Promise<void> => {
      /**
       * Проверяем, если ли id операции общего состояния
       * - Если нет, необходимо запросить
       * - Если да, используем тот, который был записан ранее в операциях общего состояния
       */
      const operationId = rootGetters['operation/hasOperationId']
        ? rootGetters['operation/operationId']
        : await dispatch('operation/createOperation', operation, {
            root: true,
          }).catch((error) =>
            dispatch('handleBackupItemError', { error, options })
          )

      const payload = { ...params, operation_id: operationId }

      return api.webrestore.getBackupItem(payload).then((response) => {
        const view = getters.currentView
        const { data, meta, status } = response

        commit('SET_TREE_DIRECTORY_PATH', path.split('/').slice(2))
        commit('SET_BACKUP_DIRECTORY_TREE_META', {
          [path]: { ...getters.defaultBackupMeta, ...meta },
        })
        commit('SET_BACKUP_DIRECTORY_TREE', {
          [path]: {
            status,
            id: operationId,
            items: data ?? [],
          },
        })

        replaceRoute({
          path: `/backup/${subtype}/${getEncodedPath(path)}`,
          query: { view },
        })

        if (status === EOperationState.PROGRESS) {
          return new Promise<void>((resolve) =>
            commit(
              'operation/SET_OPERATION_TIMEOUT_ID',
              setTimeout(resolve, DELAY.WEB_RESTORE_OPERATION),
              { root: true }
            )
          ).then(() => fetchBackupItem())
        }

        return Promise.resolve()
      })
    }

    return fetchBackupItem()
      .catch((error) => dispatch('handleBackupItemError', { error, options }))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'first-directory-backup-items',
        })
      })
  },

  /**
   * fetchNextDirectoryBackupItems
   * ? Извлечь все последующие (выбранные) директории с элементами бэкапа
   *
   * @param {BackupContext} ctx context
   * @param {IDirectoryBackupOptions} options параметры запроса и результирующие данные
   * @returns {Promise<void>}
   */
  fetchNextDirectoryBackupItems: async (
    { commit, dispatch, getters, rootGetters }: BackupContext,
    options: IDirectoryBackupOptions
  ): Promise<void> => {
    const view = getters.currentView
    const { params, path, subtype, operation, isSorted, isLoadMore } = options
    const { status } = getters.backupTree[path] ?? {}

    /**
     * При переходе на другую директорию, очищаем таймаут, чтобы избежать повторных вызовов для предыдущей директории, если она имела статус EOperationState.PROGRESS
     */
    clearTimeout(rootGetters['operation/operationTimeoutId'])

    if (!isSorted && !isLoadMore && status === EOperationState.DONE) {
      replaceRoute({
        path: `/backup/${subtype}/${getEncodedPath(path)}`,
        query: { view },
      })
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'next-directory-backup-items',
    })

    const fetchBackupItem = async (): Promise<void> => {
      /**
       * Проверяем, если ли за кэшированный id операции для "конкретной" директории
       * - Если нет, необходимо запросить
       * - Если да, используем которые был записан ранее
       */
      const { id: cachedOperationId } = getters.backupTree[path] ?? {}
      const operationId = cachedOperationId
        ? cachedOperationId
        : await dispatch('operation/createOperation', operation, {
            root: true,
          }).catch((error) =>
            dispatch('handleBackupItemError', { error, options })
          )
      const payload = { ...params, operation_id: operationId }

      return api.webrestore.getBackupItem(payload).then((response) => {
        const { data, meta, status } = response
        const { order_by, direction } = params
        const newBackupItems = data ?? []
        const currentBackupItems = getters.backupTree[path]?.items ?? []
        const currentBackupItemsLength = currentBackupItems.length
        const formedBackupItems = currentBackupItems.toSpliced(
          currentBackupItemsLength,
          newBackupItems.length,
          ...newBackupItems
        )

        commit('UPDATE_BACKUP_DIRECTORY_TREE', {
          path,
          backupOperation: {
            status,
            id: operationId,
            items: isLoadMore ? formedBackupItems : newBackupItems,
          },
        })

        commit('UPDATE_BACKUP_DIRECTORY_TREE_META', {
          path,
          meta: isSorted
            ? { ...getters.backupTreeMeta[path], order_by, direction }
            : {
                ...getters.defaultBackupMeta,
                ...meta,
                order_by,
                direction,
              },
        })

        replaceRoute({
          path: `/backup/${subtype}/${getEncodedPath(path)}`,
          query: { view },
        })

        if (status === EOperationState.PROGRESS) {
          return new Promise<void>((resolve) =>
            commit(
              'operation/SET_OPERATION_TIMEOUT_ID',
              setTimeout(resolve, DELAY.WEB_RESTORE_OPERATION),
              { root: true }
            )
          ).then(() => fetchBackupItem())
        }

        return Promise.resolve()
      })
    }

    return fetchBackupItem()
      .catch((error) =>
        dispatch('handleBackupItemError', {
          error,
          options,
          callback: () => {
            clearTimeout(rootGetters['operation/operationTimeoutId'])
            replaceRoute({
              path: `/backup/${subtype}/${getEncodedPath(path)}`,
              query: { view },
            })
          },
        })
      )
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'next-directory-backup-items',
        })
      )
  },

  /**
   * fetchUrlBackupImage
   * ? Извлечь текущий путь изображения бэкапа
   *
   * @param {BackupContext} ctx context
   * @param {IWrBackupPayload} payload параметры запроса
   * @returns {Promise<string>} url изображения
   */
  fetchUrlBackupImage: (
    { commit }: BackupContext,
    payload: IWrBackupPayload
  ): Promise<string> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'url-backup-image',
    })

    return api.webrestore
      .downloadWrBackupFile(payload)
      .then((blob) => URL.createObjectURL(blob))
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'url-backup-image',
        })
      )
  },
}
