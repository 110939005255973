<template>
  <el-dialog
    position="center"
    class="vpc-modal-common-error-dialog"
    :visible="visible"
    :close-on-click-modal="false"
    :show-close="false"
    :variant="isMobile ? 'fullscreen' : 'default'"
    @close="closeModal"
  >
    <div
      slot="title"
      class="vpc-modal-common-error-header"
    >
      <vpc-icon
        name="circle-error"
        size="16"
        :color="colorRedPrimary"
      />

      <p class="vpc-modal-common-error-header-title">
        {{ headerTitle }}
      </p>
    </div>

    <div class="vpc-modal-common-error-content">
      <p class="vpc-modal-common-error-info">
        {{ errorMessage }}
        <br />
        <span v-if="!hasCustomError">
          {{ $t('modal.common-error.text.try-after') }}
        </span>
      </p>

      <div class="vpc-modal-common-error-button-box">
        <el-button
          v-bind="buttonOptionsClose"
          @click="closeModal"
        >
          {{ $t('button.action.close') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import style from '@/assets/styles/_export-var.module.scss'

import VpcIcon from '@/components/common/vpc-icon.vue'

export default {
  name: 'VpcModalCommonError',

  components: {
    VpcIcon,
  },

  model: {
    prop: 'visible',
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    colorRedPrimary: style.colorRedPrimary,
  }),

  computed: {
    ...mapGetters(['isMobile', 'userCommonErrorMessage']),

    headerTitle() {
      return this.$t('modal.common-error.title')
    },

    errorMessage() {
      return (
        this.userCommonErrorMessage ||
        this.$t('modal.common-error.text.work-with-problem')
      )
    },

    hasCustomError() {
      return typeof this.userCommonErrorMessage === 'string'
    },

    buttonOptionsClose() {
      return this.isMobile
        ? { fluid: true, size: 'lg', variant: 'tonal-primary' }
        : { variant: 'outline-primary' }
    },
  },

  methods: {
    closeModal() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss">
.el-dialog__header {
  @include _mobile-only {
    & .i {
      color: $av-fixed-primary;
    }
  }
}

.vpc-modal-common-error-header {
  gap: 1.6rem;
  display: flex;
  align-items: center;

  @include _mobile-only {
    padding: 0 1.2rem 0 0.8rem;
  }
}

.vpc-modal-common-error-header-title {
  font-size: $lk-font-size-l;
  color: $lk-color-black-primary;

  @include ellipsis;
  @include _mobile-only {
    font-size: $lk-font-size-m;
    font-weight: $lk-font-weight-m;
  }
}

.vpc-modal-common-error-dialog {
  & .el-dialog--width-small.el-dialog {
    width: 48rem;
    min-width: 48rem;
  }
}

.vpc-modal-common-error-content {
  padding: 2.4rem;
}

.vpc-modal-common-error-info {
  font-weight: $lk-font-weight-s;
  line-height: 1.68rem;
  margin-bottom: 2.4rem;

  @include _mobile-only {
    line-height: 2rem;
    color: $av-solid-fixed-light;
  }
}

.vpc-modal-common-error-button-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
