import type { MutationTree } from 'vuex'
import type { IWrBackupItemResponse } from '@/api/types'
import type {
  ArchiveState,
  IDirectoryArchiveMeta,
  IDirectoryTargetBackupMeta,
  IDirectoryArchiveOperation,
  IDirectoryTargetArchiveOperation,
} from '@/store/modules/client/archive/types'

type ArchiveTree = MutationTree<ArchiveState>

import Vue from 'vue'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: ArchiveTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_VIEW
   * ? Установить текущие вид архивов
   *
   * @param {ArchiveState} state data
   * @param {string} view имя выбранной архивов
   * @returns {void}
   */
  SET_CURRENT_VIEW: (state: ArchiveState, view: string): void => {
    state.currentView = view
  },

  /**
   * SET_CURRENT_ARCHIVE_NAME
   * ? Установить текущие имя архива
   *
   * @param {ArchiveState} state data
   * @param {string} name имя выбранной архива
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE_NAME: (state: ArchiveState, name: string): void => {
    state.currentArchiveName = name
  },

  /**
   * SET_CURRENT_ARCHIVE
   * ? Установить текущий архив
   *
   * @param {ArchiveState} state data
   * @param {IWrBackupItemResponse} archive текущий архив
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE: (
    state: ArchiveState,
    archive: IWrBackupItemResponse
  ): void => {
    state.currentArchive = archive
  },

  /**
   * SET_CURRENT_FOLDER
   * ? Установить текущую директорию (папку)
   *
   * @param {ArchiveState} state data
   * @param {string} folder текущая директория (папка)
   * @returns {void}
   */
  SET_CURRENT_FOLDER: (state: ArchiveState, folder: string): void => {
    state.currentFolder = folder
  },

  /**
   * SET_CURRENT_ARCHIVE_VERSIONS
   * ? Установить список версий архива
   *
   * @param {ArchiveState} state data
   * @param {IWrBackupItemResponse[]} versions список версий текущего архива
   * @returns {void}
   */
  SET_CURRENT_ARCHIVE_VERSIONS: (
    state: ArchiveState,
    versions: IWrBackupItemResponse[]
  ): void => {
    state.currentArchiveVersions = versions
  },

  /**
   * SET_ARCHIVE_DIRECTORY_TREE
   * ? Установить директорию архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryArchiveOperation} data данные операции архива
   * @returns {void}
   */
  SET_ARCHIVE_DIRECTORY_TREE: (
    state: ArchiveState,
    data: IDirectoryArchiveOperation
  ): void => {
    state.archiveTree = { ...state.archiveTree, ...data }
  },

  /**
   * UPDATE_ARCHIVE_DIRECTORY_TREE
   * ? Обновить выбранную директорию архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryTargetArchiveOperation} data данные по содержимому архивов
   * @param {string} data.path путь запроса
   * @param {IWrBackupItemResponse[]} data.archiveOperation выбранная директория
   * @returns {void}
   */
  UPDATE_ARCHIVE_DIRECTORY_TREE: (
    state: ArchiveState,
    { path, archiveOperation }: IDirectoryTargetArchiveOperation
  ): void => {
    Vue.set(state.archiveTree, path, archiveOperation)
  },

  /**
   * SET_ARCHIVE_DIRECTORY_TREE_META
   * ? Установить дерево мета данных архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryArchiveMeta} meta мета данные архива
   * @returns {void}
   */
  SET_ARCHIVE_DIRECTORY_TREE_META: (
    state: ArchiveState,
    meta: IDirectoryArchiveMeta
  ): void => {
    state.archiveTreeMeta = { ...state.archiveTreeMeta, ...meta }
  },

  /**
   * UPDATE_ARCHIVE_DIRECTORY_TREE_META
   * ? Обновить мета данные выбранного архива
   *
   * @param {ArchiveState} state data
   * @param {IDirectoryTargetArchiveOperation} options мета данные по содержимому архивов
   * @returns {void}
   */
  UPDATE_ARCHIVE_DIRECTORY_TREE_META: (
    state: ArchiveState,
    { path, meta }: IDirectoryTargetBackupMeta
  ): void => {
    Vue.set(state.archiveTreeMeta, path, meta)
  },

  /**
   * SET_TREE_DIRECTORY_PATH
   * ? Установить список директорий архива
   *
   * @param {ArchiveState} state data
   * @param {Partial<string[]>} directories список директорий архива
   * @returns {void}
   */
  SET_TREE_DIRECTORY_PATH: (
    state: ArchiveState,
    directories: Partial<string[]>
  ): void => {
    state.treeDirectoryPath = directories
  },

  /**
   * ADD_TREE_DIRECTORY_PATH
   * ? Добавить директорию архива
   *
   * @param {ArchiveState} state data
   * @param {string} directoryName выбранная директория архива
   * @returns {void}
   */
  ADD_TREE_DIRECTORY_PATH: (
    state: ArchiveState,
    directoryName: string
  ): void => {
    state.treeDirectoryPath.push(directoryName)
  },

  /**
   * DELETE_TREE_DIRECTORY_PATH
   * ? Удалить часть пути архива
   *
   * @param {ArchiveState} state data
   * @param {number} directoryIndex индекс выбранной директории архива
   * @returns {void}
   */
  DELETE_TREE_DIRECTORY_PATH: (state: ArchiveState, directoryIndex): void => {
    state.treeDirectoryPath = state.treeDirectoryPath.slice(
      0,
      directoryIndex + 1
    )
  },

  /**
   * CLEAR_ARCHIVE_STATE
   * ? Очистить состояние содержимого архива
   *
   * @param {ArchiveState} state data
   * @returns {void}
   */
  CLEAR_ARCHIVE_STATE: (state: ArchiveState): void => {
    state.treeDirectoryPath = []
    state.currentArchiveVersions = []
  },
}
